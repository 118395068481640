<template>
    <div class="volunteer">
        <div class="head">
            <div class="title">主席助理注册</div>
            <div class="desc">感谢你对BWMUN大会的关注，希望你能在一个专注的时刻真诚地进行填写，期待你的加入 ：）</div>
        </div>
        <div class="form">
            <div class="formItem double">
                <div class="Box">
                    <div class="tip">
                        <div class="tipName">
                            <span class="color">*</span>
                            <span> 姓名</span>
                        </div>
                        <!-- <div class="desc">证书中使用，请确保与身份证件信息一致。</div> -->
                    </div>
                    <div class="inputBox">
                        <input type="text" placeholder="例如：王颖" v-model="form.uname">
                    </div>
                </div>
                <div class="Box">
                    <div class="tip">
                        <div class="tipName">
                            <span class="color">*</span>
                            <span> 性别</span>
                        </div>
                    </div>
                    <div class="radioBox">
                        <div class="radioItem">
                            <span>男</span>
                            <div class="radio" :class="form.gender == 1 ? 'radioActive' : ''" @click="chooseGender(1)"></div>
                        </div>
                        <div class="radioItem">
                            <span>女</span>
                            <div class="radio" :class="form.gender == 2 ? 'radioActive' : ''" @click="chooseGender(2)"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="formItem double">
                <div class="Box">
                    <div class="tip">
                        <div class="tipName">
                            <span class="color">*</span>
                            <span> 学校名称</span>
                        </div>
                        <div class="desc">请务必正确填写学校全称。</div>
                    </div>
                    <div class="inputBox">
                        <input type="text" placeholder="例如：北京市第四中学" v-model="form.school">
                    </div>
                </div>
                <div class="Box">
                    <div class="tip">
                        <div class="tipName">
                            <span class="color">*</span>
                            <span> 所在年级</span>
                        </div>
                    </div>
                    <div class="inputBoxWrapper">
                        <span>{{ GeadeName }}</span>
                        <div class="showList" @click="gradeFlag = !gradeFlag"><img src="./../../assets/img/down.png" alt=""></div>
                        <div class="gradeList" v-if="gradeFlag">
                            <div class="grade" v-for="item in gradeList" :key="item.grade_id" @click="chooseGrade(item)">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="formItem double">
                <div class="Box">
                    <div class="tip">
                        <div class="tipName">
                            <span class="color">*</span>
                            <span> 你的手机号</span>
                        </div>
                    </div>
                    <div class="inputBox phoneInputBox">
                        <input type="text"  v-model="form.mobile" placeholder="请输入手机号">
                        <div class="phoneBox">86｜</div>
                    </div>
                </div>
                <div class="Box">
                    <div class="tip">
                        <div class="tipName">
                            <span class="color"></span>
                            <div>&nbsp</div>
                        </div>
                    </div>
                    <div class="inputBox">
                        <input type="text" placeholder="请输入验证码"   v-model="form.code">
                        <div class="sms">获取验证码</div>
                    </div>
                </div>
            </div>
            <div class="formItem">
                <div class="tip">
                    <div class="tipName">
                        <span class="color">*</span>
                        <span> 你的常用邮箱</span>
                    </div>
                </div>
                <div class="inputBox">
                    <input type="text" placeholder=""   v-model="form.mail">
                </div>
                <div class="info">我们将通过邮箱发送面试信息，请注意查收&及时回复</div>
            </div>
            <div class="formItem" v-for="item in questionList" :key="item.question_id">
                <div class="tip">
                    <div class="tipName">
                        <span class="color"  :class="item.required == 1 ? '' : 'bgColor' ">*</span>
                        <span> {{ item.title }}</span>
                    </div>
                </div>
                <div class="inputBox">
                    <input  maxlength="150"  class="input " v-if="item.size == 1" v-model="item.content" />
                    <textarea  v-else maxlength="150"  class="input " v-model="item.content"></textarea>
                </div>
                <div class="info">
                    <div class="dec">{{ item.describe }}</div>
                    <div class="count" v-if="item.content">{{ item.content.length }}/150</div>
                </div>
            </div>
            <div class="formItem">
                <div class="tip">
                    <div class="tipName">
                        <span>* </span>
                        <span> 请上传你的PDF简历，更全面的展示自己</span>
                    </div>
                </div>
                <el-upload action="" multiple class="file" :auto-upload="false" :on-change="feedBackUploadImg">
                    <el-button slot="trigger" size="small" type="primary">上传简历</el-button>
                    <div class="lookFile" :class="form.resume ? 'hasResume' : ''" @click="lookFile">查看简历</div>
                </el-upload>
            </div>
            <div class="submitBox">
                <div class="title">提交申请后，请留意消息中心的后续通知～</div>
                <div class="buttonBox">
                    <div class="saveButton" @click="save">保存</div>
                    <div class="submit" @click="submit">提交</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { grade , question , assistant_apply} from './../../api/api'
import { put } from './../../utils/uploadOSS'
export default {
    data(){
        return {
            form : {
                uname : '',
                gender : '',
                grade_id : '',
                mobile : '',
                code : '',
                school : '',
                mail : '',
                question : '',
                resume : '',
            },
            GeadeName : '请选择',
            gradeFlag : false,
            gradeList : [],
            questionList: [],
            resume: ''
        }
    },
    mounted(){
        this.getGradeList()
        this.getQuestion()
    },
    methods : {
        save(){
            
        },
        submit(){
            if(!this.form.uname || !this.form.gender || !this.form.grade_id || !this.form.school || !this.form.mobile  || !this.form.code || !this.form.mail  || !this.form.resume ){
                this.$message.error('填完必填信息后，才能提交哦～')
                return
            }
            var arr = []
            for (var i = 0; i < this.questionList.length; i++) {
                if (!this.questionList[i].content && this.questionList[i].required == 1) {
                    this.$message.error(' 填完必填信息后，才能提交哦～')
                    return
                }
                var obj = {}
                obj.question_id = this.questionList[i].question_id
                obj.content = this.questionList[i].content
                obj.extend_content = this.questionList[i].extend_content || ''
                arr.push(obj)
            }
            this.form.question = JSON.stringify(arr)
            assistant_apply(this.form).then(res => {
                if (res.data.code === '10000') {
                    this.$message({ message: '申请成功', type: 'success' })
                    this.form = {
                        uname : '',
                        gender : '',
                        grade_id : '',
                        mobile : '',
                        code : '',
                        school : '',
                        mail : '',
                        question : '',
                        resume : '',
                    }
                    this.questionList.forEach(item => {
                        item.content = ''
                    })
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        feedBackUploadImg(file, fileList) {
            if(file.raw.type.indexOf('image') == -1){
                this.$message.error('请选择图片文件')
                return
            }
            put(file.raw).then(res => {
                this.$message({ message: '上传成功', type: 'success' })
                this.resume = res.url
                this.form.resume = res.name
            })
        },
        //查看简历
        lookFile(){
            if(this.form.resume){
                window.open(this.resume)
            }
        },
        getQuestion() {
            question({ 'role': 4 }).then(res => {
                if (res.data.code === '10000') {
                    this.questionList = res.data.data
                    // console.log(this.questionList)
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        chooseGrade(item){
            this.gradeFlag = false
            this.form.grade_id = item.grade_id
            this.GeadeName = item.name
        },
        //选择性别
        chooseGender(value){
            this.form.gender = value
        },
        //年级查询
        getGradeList(){
            grade().then(res => {
                if (res.data.code === '10000') {
                    this.gradeList = res.data.data
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
    },
    computed : {

    }
}
</script>

<style scoped lang="less">
.volunteer{
    padding-top: 144px;
    padding-bottom: 150px;
    .submitBox{
        margin-top: 40px;
        .title{
            font-size: 20px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 40px;
            text-align: center;
        }
        .buttonBox{
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-top: 90px;
        }
        .saveButton{
            width: 108px;
            height: 50px;
            background: #FFFFFF;
            border-radius: 8px;
            font-size: 18px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #000000;
            line-height: 50px;
            cursor: pointer;
        }
        .submit{
            width: 108px;
            height: 50px;
            background: #3DA6D5;
            border-radius: 8px;
            font-size: 18px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #FFFFFF;
            line-height: 50px;
            margin-left: 48px;
            cursor: pointer;
        }
    }
    .form{
        width: 792px;
        margin: 67px auto 0;
        .formItem{
            margin-top: 40px;
            
            .inputBox{
                margin-top: 6px;
                position: relative;
                input{
                    width: 100%;
                    height: 40px;
                    background: rgba(216,216,216,0.3);
                    border-radius: 8px;
                    border: 1px solid #FFFFFF;
                    outline: none;
                    text-indent: 15px;
                    font-size: 18px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #FFFFFF;
                    line-height: 38px;
                }
                textarea{
                    width: 100%;
                    height: 80px;
                    background: rgba(216,216,216,0.3);
                    border-radius: 8px;
                    border: 1px solid #FFFFFF;
                    outline: none;
                    text-indent: 15px;
                    font-size: 18px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #FFFFFF;
                    line-height: 38px;
                }
                
                .sms{
                    font-size: 18px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #1C1C1C;
                    line-height: 29px;
                    width: 104px;
                    height: 29px;
                    background: #D8D8D8;
                    border-radius: 4px;
                    position: absolute;
                    right: 5px;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: center;
                    cursor: pointer;
                }
                .phoneBox{
                    position: absolute;
                    // border-right: 2px solid #fff;
                    // padding-right: 12px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 18px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #FFFFFF;
                    line-height: 25px;
                    img{
                        width: 21px;
                        height: 26px;
                    }
                }
            }
            .phoneInputBox{
                input{
                    text-indent: 62px;
                }
            }
            .tip{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .tipName{
                    font-size: 20px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #fff;
                    line-height: 40px;
                }
                .desc{
                    font-size: 16px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #777777;
                    line-height: 22px;
                }
            }
            .info{
                height: 22px;
                font-size: 16px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #777777;
                line-height: 22px;
                margin-top: 6px;
                margin-left: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .count{
                    font-size: 14px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #FFFFFF;
                    line-height: 20px;
                }
            }
        }
        .double{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .Box{
                width: 384px;
                .inputBoxWrapper{
                    height: 40px;
                    background: rgba(216,216,216,0.3);
                    border-radius: 8px;
                    border: 1px solid #FFFFFF;
                    font-size: 18px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #FFFFFF;
                    line-height: 38px;
                    text-indent: 8px;
                    position: relative;
                    .showList{
                        width: 26px;
                        height: 15px;
                        position: absolute;
                        right: 14px;
                        top: 50%;
                        margin-top: -7px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .gradeList{
                        width: 384px;
                        height: 180px;
                        background: #000000;
                        border-radius: 10px;
                        border: 1px solid #FFFFFF;
                        overflow: auto;
                        position: absolute;
                        z-index: 99;
                        top: 40px;
                        left: 0;
                        scrollbar-color: #fff transparent; 
                        scrollbar-width: thin; 
                        .grade{
                            height: 45px;
                            font-size: 18px;
                            font-family: AlibabaPuHuiTi_2_55_Regular;
                            color: #FFFFFF;
                            line-height: 45px;
                            text-align: center;
                            cursor: pointer;
                        }
                        .grade:hover{
                            background: #3DA6D5;
                        }
                        
                    }
                    ::-webkit-scrollbar {/*滚动条整体样式*/
                        width: 8px;
                        background: transparent;
                        border-radius: 9px;
                    }
                    ::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
                        background: #fff;
                        border-radius: 9px;
                    }
                }
                .radioBox{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .radioItem{
                        width: 180px;
                        height: 40px;
                        background: rgba(216,216,216,0.3);
                        border-radius: 8px;
                        border: 1px solid #FFFFFF;
                        font-size: 18px;
                        font-family: AlibabaPuHuiTi_2_55_Regular;
                        color: #FFFFFF;
                        line-height: 38px;
                        text-indent: 15px;
                        position: relative;
                        .radio{
                            width: 24px;
                            height: 24px;
                            border-radius: 8px;
                            border: 1px solid #FFFFFF;
                            position: absolute;
                            right: 11px;
                            top: 50%;
                            margin-top: -12px;
                        }
                    }
                }
            }
        }
        .file {
            margin-top: 32px;
            display: flex;
            /deep/ .el-input__inner {
                padding: 0;
            }

            /deep/ .el-button {
                width: 108px;
                height: 50px;
                background: rgba(216,216,216,0.3);
                border-radius: 8px;
                border: 1px solid #FFFFFF;
                font-size: 18px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 48px;
                padding: 0;
                margin-left: 55px;
            }
            .lookFile{
                width: 108px;
                height: 50px;
                background: #272727;
                border-radius: 8px;
                border: 1px solid rgba(255,255,255,0.2);
                font-size: 18px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #777777;
                line-height: 48px;
                margin-left: 40px;
                text-align: center;
                cursor: pointer;
            }
            .hasResume{
                background: rgba(216,216,216,0.3);
                border: 1px solid #FFFFFF;
                color: #FFFFFF;
            }
        }
    }
    .head{
        margin: 120px auto 0;
        text-align: center;
        .title{
            font-size: 40px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 52px;
        }
        .desc{
            font-size: 18px;
            font-family: AlibabaPuHuiTi_2_85_Bold;
            color: #FFFFFF;
            line-height: 40px;
            margin-top: 48px;
        }
    }
}
</style>