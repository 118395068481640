<template>
    <div class="food">
        <div class="videoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ $store.state.language.type == 1 ? data.title : data.title_en }}</div>
            <div class="title2">{{ $store.state.language.type == 1 ? data.describe : data.describe_en }}</div>
            <video :src="data.url" autoplay loop muted></video>
        </div>
        <div class="typeBox typeBox1">
            <div class="type">
                <span>住宿</span>
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 4" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/foodone' + item + '.png' " alt="" class="img">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    大会期间，代表们将统一入住会场所在酒店。酒店的设施完备，环境舒适优美。无论是酒店的硬件设施、还是人性化的服务管理，都满足BetterWorld全球青少年模拟联合国组委会对会场和住宿的高标准要求，进而为本次大会所有的参会代表以及家长们，在不出国门的情况下，感受世界级模拟联合国大会所带来的沉浸式体验和魅力。
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>餐饮</span>
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/foodtwo' + item + '.png' " alt="" class="img">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    大会期间的早餐是自助餐，帮忙代表们开启满满活力的一天。午餐是商务午餐，有助于大家就餐结束后，快速回归会议状态。晚餐是自助餐，帮助大家补充能量，以最好的状态迎接新的日程安排。
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>着装</span>
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/foodthree' + item + '.png' " alt="" class="img">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    会议期间代表需穿正装出席会场。男生需穿西装套装、衬衫、领带、深色长袜、深色皮鞋。女生需穿西装套装或裙装、衬衫、皮鞋或高跟鞋。会场室内均有恒温空调，全程保持在一个舒适的温度。
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>交通</span>
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/foodfourth' + item + '.png' " alt="" class="img">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    大家需自行前往会场，会场地址为：深圳国际会展中心皇冠假日酒店（广东省深圳市宝安区展云路8号）。会场距离深圳宝安国际机场约16公里，驾车约35分钟。会场距离地铁20号线国展南站C出口100米。
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {config} from './../../utils/config'
import {banner} from './../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                url : '',
            },
            load : false,
            configData : {}
        }
    },
    beforeMount(){
        if(this.$store.state.language.type == 1){
            this.configData = config.weiyuan.chinese
        }else{
            this.configData = config.weiyuan.english
        }
    },

    
    mounted(){
        banner({'seat' : 9}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    watch : {
		"$store.state.language" : function (){
			if(this.$store.state.language.type == 1){
				this.configData = config.weiyuan.chinese
			}else{
				this.configData = config.weiyuan.english
			}
		}
	}
}
</script>

<style lang="less" scoped>
.food{
    padding-bottom: 150px;
    background: #1C1C1C;
    .typeBox{
        margin-top: 64px;
        .info{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            .left{
                width: 587px;
                /deep/ .carousel{
                    .el-carousel__container{
                        height: 387px;
                        background: #ccc;
                        border-radius: 8px;
                        overflow: hidden;
                        .content{
                            height: 100%;
                            img{
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                    .el-carousel__indicator--horizontal{
                        padding: 16px 15px 0 0;
                    }
                }
            }
            .text{
                width: 521px;
                font-size: 20px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #fff;
                line-height: 40px;
                margin-left: 91px;
                padding-bottom: 24px;
            }
            .color{
                color: #3DA6D5;
            }
        }
        .type{
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 44px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 54px;
                height: 44px;
                margin-left: 30px;
            }
        }
    }
    .typeBox1{
        margin-top: 120px;
    }
}
.videoBox{
    video{
        top: unset;
        bottom: 0;
    }
}
</style>