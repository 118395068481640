<template>
    <div class="contact">
        <div class="videoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ $store.state.language.type == 1 ? data.title : data.title_en }}</div>
            <div class="title2">{{ $store.state.language.type == 1 ? data.describe : data.describe_en }}</div>
            <video :src="data.url" autoplay loop muted></video>
        </div>
        <div class="content">
            <div class="title">{{ configData.title1 }}</div>
            <div class="tipBox">
                <div class="itemBox">
                    <div class="tip">
                        <span>* </span>
                        <span class="name">{{ configData.title2 }}</span>
                    </div>
                    <el-input    class="input" v-model="form.uname"></el-input>
                </div>
                <div class="itemBox">
                    <div class="tip">
                        <span>* </span>
                        <span class="name">{{ configData.title3 }}</span>
                    </div>
                    <el-input    class="input" v-model="form.weixin"></el-input>
                </div>
            </div>
            
            <div class="tipBox phone">
                <div class="itemBox">
                    <div class="tip">
                        <span>* </span>
                        <span class="name">{{ configData.title5 }}</span>
                    </div>
                    <el-input    class="input" :placeholder="configData.title6" v-model="form.mobile" maxlength="11">
                        <template slot="prepend">86｜</template>
                    </el-input>
                </div>
                <div class="itemBox">
                    <div class="tip">
                        <span>&nbsp</span>
                        <span class="name"> </span>
                    </div>
                    <el-input   class="input" :placeholder=" configData.title7 " v-model="form.code" maxlength="6">
                        <template slot="append">
                            <div @click="getSms" class="smsButton">{{ smsText }}</div>
                        </template>
                    </el-input>
                </div>
            </div>
            

            <div class="tipBox">
                <div class="itemBox">
                    <div class="tip">
                        <span>* </span>
                        <span class="name">{{ configData.title4 }}</span>
                    </div>
                    <el-input    class="input" v-model="form.mail"></el-input>
                </div>
                <div class="itemBox">
                    <div class="tip">
                    <span>* </span>
                    <span class="name">{{ configData.title8 }}</span>
                </div>
                <div class="themeBox" @click="theme = !theme">
                    {{ themeText() }}
                    <i class="icon el-icon-arrow-down" :class="theme ? 'up' : '' "></i>
                    <div class="themeList" v-if="theme">
                        <div class="type" @click.stop="chooseType(1)">注册</div>
                        <div class="type" @click.stop="chooseType(2)">大会</div>
                        <div class="type" @click.stop="chooseType(3)">合作</div>
                        <div class="type" @click.stop="chooseType(4)">其他</div>
                    </div>
                </div>
                </div>
            </div>
            <div class="tip textareaTip">
                <span>* </span>
                <span class="name">{{ configData.title9 }}</span>
            </div>
            <div class="textareaBox">
                <el-input  maxlength="150"  class="input info" type="textarea" v-model="form.content"></el-input>
                <div class="count" v-if="form.content">{{ form.content.length }}/150</div>
            </div>

            <div class="button" @click="submit">提交</div>

            <div class="iconList">
                <img src="./../../assets/img/contact1.png" alt="" class="firstImg">
                <img src="./../../assets/img/contact2.png" alt="">
                <img src="./../../assets/img/contact3.png" alt="">
                <img src="./../../assets/img/contact4.png" alt="">
                <img src="./../../assets/img/contact5.png" alt="">
                <img src="./../../assets/img/contact6.png" alt="">
                <img src="./../../assets/img/contact7.png" alt="">
                <img src="./../../assets/img/contact8.png" alt="">
                <img src="./../../assets/img/contact9.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import {banner , contact, sms } from './../../api/api'
import {config} from './../../utils/config'
export default {
    data(){
        return {
            form : {
                uname : '',
                mobile : '',
                code : '',
                weixin : '',
                mail : '',
                theme_type : '',
                content : '',
            },
            data : {
                title : '',
                describe : '',
                url : '',
            },
            theme : false,
            load : false,
            smsText: '获取验证码',
            timer: '',
            numberCount: 0,
            configData : {}
        }
    },
    mounted(){
        if(this.$store.state.language.type == 1){
            this.configData = config.contact.chinese
            this.smsText = '获取验证码'
        }else{
            this.configData = config.contact.english
            this.smsText = 'Verification'
        }
        banner({'seat' : 6}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    methods : {
        //获取验证码
        getSms() {
            if (this.numberCount > 0) return
            if(!this.form.mobile){
                this.$message.error('请先输入手机号')
                return
            }
            var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if(!myreg.test(this.form.mobile)){
                this.$message.error('请输入正确的手机号')
                return
            }
            var obj = {}
            obj.mobile = this.form.mobile
            obj.event = 1
            sms(obj).then(res => {
                this.numberCount = 60
                this.$message({ message: '验证码已发送', type: 'success' })
                this.timer = setInterval(() => {
                    if (this.numberCount <= 1) {
                        clearInterval(this.timer)
                        this.numberCount = 0
                        if(this.$store.state.language.type == 1){
                            this.smsText = '获取验证码'
                        }else{
                            this.smsText = 'Verification'
                        }
                        return
                    }
                    this.numberCount--
                    this.smsText = this.numberCount + 's'
                }, 1000)
            })
        },
        chooseType(type){
            this.form.theme_type = type
            this.theme = false
        },
        submit(){
            if(!this.form.weixin || !this.form.mobile || !this.form.code || !this.form.uname || !this.form.mail || !this.form.theme_type  || !this.form.content ){
                this.$message.error('填完必填信息后，才能提交哦～')
                return
            }
            contact(this.form).then(res => {
                if (res.data.code === '10000') {
                    this.$message({ message: '申请成功', type: 'success' })
                    this.form = {}
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
    },
    computed : {
        themeText(){
            return () => {
                if(this.form.theme_type == 1){
                    return '注册'
                }else if(this.form.theme_type == 2){
                    return '大会'
                }else if(this.form.theme_type == 3){
                    return '合作'
                }else if(this.form.theme_type == 4){
                    return '其他'
                }
            }
        }
    },
    watch : {
		"$store.state.language" : function (){
			if(this.$store.state.language.type == 1){
                this.configData = config.contact.chinese
                this.smsText = '获取验证码'
            }else{
                this.configData = config.contact.english
                this.smsText = 'Verification'
            }
		}
	}
}
</script>
<style lang="less" scoped>
.contact{
    background: #1C1C1C;
    padding-bottom: 150px;
    .textareaBox{
        position: relative;
        width: 792px;
        margin: 17px auto 0;
        .count{
            position: absolute;
            bottom: -25px;
            right: 0;
            font-size: 14px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #FFFFFF;
            line-height: 20px;
        }
    }
    .textareaTip{
        width: 100%;
    }
    .themeBox{
        width: 384px;
        height: 40px;
        background: rgba(216,216,216,0.3);
        border-radius: 10px;
        border: 1px solid #FFFFFF;
        margin: 17px auto 0;
        position: relative;
        font-size: 18px;
        font-family: AlibabaPuHuiTi_2_55_Regular;
        color: #FFFFFF;
        line-height: 38px;
        padding: 0;
        text-indent: 14px;
        .icon{
            line-height: 38px;
            color: #fff;
            position: absolute;
            right: 25px;
            top: 0;
            transition: 0.2s;
            text-indent: 0;
        }
        .up{
            transform: rotate(180deg);
        }
        .themeList{
            width: 100%;
            height: 200px;
            background: #000000;
            border-radius: 10px;
            border: 1px solid #FFFFFF;
            position: absolute;
            left: -1px;
            top: 38px;
            z-index: 999;
            font-size: 18px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #FFFFFF;
            line-height: 50px;
            text-indent: 14px;
            cursor: pointer;
            overflow: hidden;
            .type:hover{
                background: #3DA6D5;
            }
        }
    }
    .content{
        width: 792px;
        margin: 0 auto;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-direction: column;
        .iconList{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 100px;
            img{
                width: 47px;
                height: 34px;
                margin-left: 21px;
            }
            .firstImg{
                margin: 0;
            }
        }
        .button{
            width: 106px;
            height: 50px;
            background: #3DA6D5;
            border-radius: 10px;
            font-size: 18px;
            font-family: AlibabaPuHuiTi_2_85_Bold;
            color: #FFFFFF;
            line-height: 50px;
            text-align: center;
            margin-top: 80px;
            cursor: pointer;
        }
        
        .title{
            text-align: center;
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 44px;
            margin-top: 64px;
            margin-bottom: 20px;
        }
        .input{
            height: 40px;
            background: rgba(216,216,216,0.3);
            border-radius: 10px;
            border: 1px solid #FFFFFF;
            margin: 17px auto 0;
            overflow: hidden;
            /deep/ .el-input__inner , /deep/ .el-textarea__inner{
                height: 100%;
                background: transparent;
                outline: none;
                border: none;
                font-size: 18px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 38px;
                padding: 0 14px;
                resize: none;
            }
            /deep/ .el-textarea__inner{
                line-height: 30px;
            }
            /deep/ .el-input-group__prepend{
                background: transparent;
                border: none;
                font-size: 18px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 38px;
                padding: 0;
                text-indent: 14px;
            }
        }
        /deep/ .el-input__inner:focus{
            background: #1C1C1C;
        }
        /deep/ .el-textarea__inner:focus{
            background: #1C1C1C;
        }
        .info{
            height: 157px;
            margin: 0;
        }
        .tip{
            font-size: 20px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #3DA6D5;
            line-height: 40px;
            text-align: left;
            margin-top: 30px;
            .name{
                color: #fff;
            }
        }
        .phone{
            .input:nth-of-type(1){
                /deep/ .el-input__inner{
                    text-indent: 0;
                    padding: 0;
                }
            }
            .input:nth-of-type(2){
                /deep/ .el-input-group__append{
                    width: 103px;
                    height: 29px;
                    background: #D8D8D8;
                    border-radius: 8px;
                    font-size: 18px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #1C1C1C;
                    line-height: 29px;
                    border: none;
                    padding: 0;
                    text-align: center;
                    display: block;
                    position: absolute;
                    right: 13px;
                    top: 50%;
                    margin-top: -15px;
                    cursor: pointer;
                }
            }
        }
        .tipBox{
            display: flex;
            .tip{
                width: 378px;
            }
            .itemBox:nth-of-type(2){
                margin-left: 24px;
            }
        }
    }
    .videoBox{
        // background-image: url('./../../assets/img/contactBanner.png');
        video{
            top: unset;
            bottom: 0;
        }
    }
}
</style>