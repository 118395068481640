<template>
    <div class="food">
        <div class="videoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ $store.state.language.type == 1 ? data.title : data.title_en }}</div>
            <div class="title2">{{ $store.state.language.type == 1 ? data.describe : data.describe_en }}</div>
            <video :src="data.url" autoplay loop muted></video>
        </div>
        <div class="typeBox typeBox1">
            <div class="type">
                <span>北京环球度假区</span>
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 2" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/cityone' + item + '.png' " alt="" class="img">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    北京环球度假区包含七大主题景区，分别有“哈利波特的魔法世界”、“侏罗纪世界”、“变形金刚基地”、“小黄人乐园”、“功夫熊猫盖世之地”、“好莱坞”、“未来水世界”。打卡完喜欢的主题景区后，再到“能量晶体补充站”、“落霞餐厅”、“哈蒙德餐厅”、“漂流者小馆”享受各市中西美食。对了，千万别错过环球全明星大巡游、以及去纪念品商店，把快乐打包带回家。
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>故宫博物院</span>
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 2" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/citytwo' + item + '.png' " alt="" class="img">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    故宫是中国最著名的古建筑之一，也是中国明清两代的皇宫。故宫建于明朝，是中国最大的古代宫殿建筑群，也是世界上最大的宫殿建筑群之一。游客可以在这里欣赏到中国古代宫廷建筑的精美和华丽，感受到中国古代文化的博大精深。故宫博物院被评为国家5A级旅游景区和世界遗产。
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>天坛</span>
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 2" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/citythree' + item + '.png' " alt="" class="img">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    北京天坛，是明、清两朝皇帝祭天、求雨和祈祷丰年的专用场所，也是中国古建筑中的璀璨明珠。雕梁画栋的古建筑，古树名木苍翠葱郁，营造出庄严肃穆的氛围。祈年殿是天坛的著名建筑，鎏金宝顶，蓝瓦红柱，蓝天白云下的祈年殿拍照十分出片！游客可以在这里领略古代宗教文化的魅力，感受中国传统文化的博大精深。天坛公园被评为国家5A级旅游景区和世界遗产。
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>颐和园</span>
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 2" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/cityfourth' + item + '.png' " alt="" class="img">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    颐和园，是中国现存最大的皇家园林之一，世界上独一无二的园林景观。走进这座历史悠久、文化底蕴深厚的皇家园林，有古朴秀雅的亭台楼阁建筑，也有湖广山色的自然美景。园林中的每一个角落都充满了诗情画意，游客可以在这里体验皇家园林的美景和历史文化的魅力。颐和园被评为国家5A级旅游景区和世界遗产。
                </div>
            </div>
        </div>
        <div class="typeBox">
            <div class="type">
                <span>八达岭长城</span>
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 2" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/cityfive' + item + '.png' " alt="" class="img">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    长城是中国的标志性建筑，也是中国古代的军事防御工程。长城始建于春秋战国时期，历经数百年的修建和扩建，成为了中国古代的一道壮丽的风景线。游客可以在长城上欣赏到壮观的山水风光，感受到中国古代文化的博大精深。八达岭长城是明长城中保存最好的一段，地势险要，城关坚固。八达岭-慕田峪长城旅游区被评为国家5A级旅游景区。
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {config} from './../../utils/config'
import {banner} from './../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                url : '',
            },
            load : false,
            configData : {}
        }
    },
    beforeMount(){
        if(this.$store.state.language.type == 1){
            this.configData = config.weiyuan.chinese
        }else{
            this.configData = config.weiyuan.english
        }
    },
    mounted(){
        
        banner({'seat' : 10}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    watch : {
		"$store.state.language" : function (){
			if(this.$store.state.language.type == 1){
				this.configData = config.weiyuan.chinese
			}else{
				this.configData = config.weiyuan.english
			}
		}
	}
}
</script>

<style lang="less" scoped>
.food{
    padding-bottom: 150px;
    background: #1C1C1C;
    .typeBox{
        margin-top: 64px;
        .info{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            .left{
                width: 587px;
                /deep/ .carousel{
                    .el-carousel__container{
                        height: 387px;
                        background: #ccc;
                        border-radius: 8px;
                        overflow: hidden;
                        .content{
                            height: 100%;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .el-carousel__indicator--horizontal{
                        padding: 16px 15px 0 0;
                    }
                }
            }
            .text{
                width: 521px;
                font-size: 20px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 32px;
                margin-left: 92px;
                padding-bottom: 24px;
            }
            .color{
                color: #3DA6D5;
            }
        }
        .type{
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 44px;
            text-align: center;
        }
    }
}
.videoBox{
    video{
        top: unset;
        bottom: 0;
    }
}
</style>