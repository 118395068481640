<template>
    <div class="about">
        <div class="videoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ $store.state.language.type == 1 ? data.title : data.title_en }}</div>
            <div class="title2">{{ $store.state.language.type == 1 ? data.describe : data.describe_en }}</div>
            <video :src="data.url"  loop muted ref="video" autoplay></video>
        </div>
        <div class="content">
            <div class="ted">
                <div class="title">什么是BetterWorldMUN？ </div>
                <div class="info">
                    <div class="img">
                        <img src="./../../assets/img/about1.png" alt="">
                    </div>
                    <div class="text">
                        <span class="color">BetterWorld全球青少年模拟联合国大会</span>（BetterWorldMUN），是一个专为4-12年级学生举办的为期四天的国际关系模拟活动，地点在中国海南省三亚市。在<span class="color">BetterWorldMUN</span>，代表们通过扮演联合国代表以及其他国际机构和国家内阁成员的角色，深入研究国际事务、联合国运作模式、公共演讲和外交技巧。会议期间，代表们将了解平衡国家利益与国际社会需求的重要性，就世界领导人面临的日常问题进行辩论，并最终起草相应的解决方案。
                    </div>
                </div>
            </div>
            <div class="ted">
                <div class="title">BetterWorldMUN的愿景</div>
                <div class="info">
                    <div class="img">
                        <img src="./../../assets/img/about2.png" alt="">
                    </div>
                    <div class="text">
                        秉承联合国的精神，<span class="color">BetterWorldMUN</span>致力于打造一个建设性论坛，就一系列国际问题进行公开对话。我们通过模拟当前、历史和未来的重大事件，营造一个吸引未来外交官、政治家、企业家参与的环境。我们鼓励代表们批判性地看待周围的世界，并以创新性的方式思考，年轻人可以做些什么来影响自己的社区和整个世界的变化。
                    </div>
                </div>
            </div>
            <div class="ted">
                <div class="title">为什么选择BetterWorldMUN？</div>
                <div class="info">
                    <div class="img">
                        <img src="./../../assets/img/about3.png" alt="">
                    </div>
                    <div class="text">
                        <span class="color">BetterWorldMUN</span>
                        致力于为来自世界各地的代表提供丰富的学术辩论并培养其领导力，让代表们辩论当今世界面临的最紧迫问题，并起草创新、创造性的解决方案。参与者将在整个过程中培养多种技能，包括但不限于：公开演讲、创造性规划、批判性思维、谈判、团队合作、领导力和政策制定。
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {banner} from './../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                url : '',
            },
            load : false
        }
    },
    mounted(){
        banner({'seat' : 5}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    methods : {
        canplay(){
            this.$refs.video.play()
        }
    }
}
</script>

<style lang="less" scoped>
.about{
    background: #1C1C1C;
    .content{
        width: 1202px;
        margin: 0 auto;
        overflow: hidden;
        padding-bottom: 162px;
        .ted{
            margin-top: 160px;
            .info{
                margin-top: 48px;
                overflow: hidden;
                display: flex;
                align-items: center;
                .text{
                    font-size: 20px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    font-weight: 400;
                    color: #D1D1D1;
                    line-height: 40px;
                    margin-left: 32px;
                    .color{
                        color: #3DA6D5;
                    }
                }
                .img{
                    float: left;
                    img{
                        width: 587px;
                        height: 284px;
                        border-radius: 8px;
                    }
                }
            }
            .title{
                font-size: 34px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                line-height: 40px;
                text-align: center;
                .color{
                    color: #3DA6D5;
                }
            }
        }
        .ted:nth-of-type(1){
            margin-top: 64px;
        }
    }
    .team{
        text-align: center;
        margin: 64px auto 0;
        .title{
            font-size: 48px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 62px;
        }
        img{
            width: 275px;
            height: 274px;
            margin: 30px auto 0;
        }
        .name{
            font-size: 36px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 40px;
            margin-top: 25px;
        }
        .info{
            font-size: 22px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            font-weight: 400;
            color: #D1D1D1;
            line-height: 40px;
            margin-top: 13px;
        }
    }
    .videoBox{
        // background-image: url('./../../assets/img/aboutBanner.png');
        video{
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
</style>