<template>
    <div class="personRegister">
        <div class="personInfo">
            <div class="title">团队信息</div>
            <div class="form">
                <div class="formItem">
                    <div class="tip">
                        <div class="tipName">
                            <span class="color">*</span>
                            <span> 姓名</span>
                        </div>
                    </div>
                    <div class="inputBox">
                        <input type="text" placeholder="例如：王颖" v-model="form.uname">
                    </div>
                    <div class="desc">证书中使用，请确保与身份证件信息一致。</div>
                </div>
                <div class="formItem">
                    <div class="tip">
                        <div class="tipName">
                            <span class="color">*</span>
                            <span> 姓名拼音</span>
                        </div>
                    </div>
                    <div class="inputBox">
                        <input type="text" placeholder="例如：WANG YING" v-model="form.pinyin">
                    </div>
                    <div class="desc">姓氏在前，全大写。</div>
                </div>
                <div class="formItem double">
                    <div class="Box">
                        <div class="tip">
                            <div class="tipName">
                                <span class="color">*</span>
                                <span> 身份</span>
                            </div>
                        </div>
                        <div class="radioBox">
                            <div class="radioItem">
                                <span>首席代表</span>
                                <div class="radio" :class="form.gender == 1 ? 'radioActive' : ''" @click="chooseGender(1)"></div>
                            </div>
                            <div class="radioItem">
                                <span>指导老师</span>
                                <div class="radio" :class="form.gender == 2 ? 'radioActive' : ''" @click="chooseGender(2)"></div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="formItem">
                    <div class="tip">
                        <div class="tipName">
                            <span class="color">*</span>
                            <span> 学校名称</span>
                        </div>
                    </div>
                    <div class="inputBox">
                        <input type="text" placeholder="例如：北京市第四中学" v-model="form.school">
                    </div>
                    <div class="desc">请务必正确填写学校全称。</div>
                </div>
                <div class="formItem double">
                    <div class="Box">
                        <div class="tip">
                            <div class="tipName">
                                <span class="color">*</span>
                                <span> 手机</span>
                            </div>
                        </div>
                        <div class="inputBox phoneInputBox">
                            <input type="text"  v-model="form.mobile">
                            <div class="phoneBox"><img src="./../../../assets/img/phone.png" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="formItem">
                    <div class="inputBox">
                        <input type="text" placeholder="请输入验证码"   v-model="form.code">
                        <div class="sms">获取验证码</div>
                    </div>
                    <div class="desc">确保手机号码正确，可接收短信。</div>
                </div>
                <div class="formItem">
                    <div class="tip">
                        <div class="tipName">
                            <span class="color">*</span>
                            <span>  邮箱</span>
                        </div>
                    </div>
                    <div class="inputBox">
                        <input type="text" placeholder="常用邮箱"   v-model="form.mail">
                    </div>
                </div>
                <div class="formItem">
                    <div class="tip">
                        <div class="tipName">
                            <span class="color">*</span>
                            <span> 身份证号码/护照号码</span>
                        </div>
                    </div>
                    <div class="inputBox">
                        <input type="text" placeholder="仅限购买大会期间保险使用，请放心填写。"  v-model="form.idcard">
                    </div>
                </div>
            </div>
        </div>

        <div class="assemblyInfo">
            <div class="title">大会信息</div>
            <div class="desc">
                <div class="isneed">
                    <div class="desc">
                        <span class="color">* </span>
                        <span>是否需要自选委员会？</span>
                    </div>
                    <div class="chooseBox">
                        <div class="radio">
                            <span>是，我要选择</span>
                            <div class="radioBox" :class="assemblyType == 1 ? 'radioActive' : ''" @click="changeassemblyType(1)"></div>
                        </div>
                        <div class="radio">
                            <span>否，随机分配</span>
                            <div class="radioBox" :class="assemblyType == 2 ? 'radioActive' : ''" @click="changeassemblyType(2)"></div>
                        </div>
                    </div>
                </div>
                <div class="text1" v-if="assemblyType == 1">
                    <span class="color">*</span>
                    <span>  英文场要求全程使用英文参与，请根据自己的英语能力选择委员会。</span>
                </div>
            </div>
            <div class="formItem" v-if="assemblyType == 2">
                <div class="tip">
                    <div class="tipName">
                        <span class="color">*</span>
                        <span>  代表团人数</span>
                    </div>
                </div>
                <div class="inputBox">
                    <input type="text" placeholder=""  v-model="form.team_number"  @input="teamNumberTest">
                </div>
            </div>
            <div class="list" v-if="assemblyType == 1">
                <div class="wrapper" v-for="item in CommitteeList.theme_list" :key="item.theme_id">
                    <img :src="item.cover" alt="">
                    <div class="listBox"   :class="activeList(item)">
                        <div class="line1">
                            <span v-if="item.is_new == 1">* 新增</span>
                            <span>委员会：{{ item.cmte_name }}</span>
                        </div>
                        <div class="themeList" v-for="(theme , index) in item.theme_name" :key="index">
                            <div class="index">主题{{String.fromCharCode(64 + parseInt(index + 1))}}：</div>
                            <div class="themeName">{{ theme }}</div>
                        </div>
                        <div class="restCount" v-if="item.rest_count != null ">
                            <span v-if="item.rest_count > 0" class="color">余 {{ item.rest_count }} 位</span>
                            <span v-else>已空</span>
                        </div>
                        <div class="color groupType">{{ groupTypeFn(item) }}</div>
                    </div>
                    <div class="numberBox">
                        <div class="reduce" @click="changeNumber(-1 , item)">
                            <div class="line"></div>
                        </div>
                        <div class="number">{{ item.number }}</div>
                        <div class="add" @click="changeNumber(1 , item)">
                            <div class="line"></div>
                            <div class="line"></div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="theme">
                <div class="themeTitle">{{ CommitteeList.name }}</div>
                <div class="cover">
                    <img :src="CommitteeList.cover" alt="">
                </div>
                <div class="info">
                    <div class="expenseDetail">
                        <div class="detailBox" v-for="(item , index) in CommitteeList.expenseDetail" :key="index">
                            <div class="circle"></div>
                            <div class="text">{{ item }}</div>
                        </div>
                    </div>
                    <div class="moneyInfo"><span class="color">{{form.team_number ? form.team_number : '0'}} X </span><span class="moneyNum">¥</span>{{ CommitteeList.price }}</div>
                    <div class="countMoney"><span class="moneyNum">¥</span>{{ form.team_number * CommitteeList.price ? form.team_number * CommitteeList.price : '0,000' }}</div>
                    <div class="time">
                        <span class="day">{{ CommitteeList.startDate }}</span>
                        <div class="countDate">
                            <div class="line line1"></div>
                            <span>{{ CommitteeList.countDate }}天</span>
                            <div class="line line2"></div>
                        </div>
                        <span class="day">{{ CommitteeList.endDate }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="stay">
            <div class="title">住宿&集训信息</div>
            <div class="isneed">
                <div class="desc">
                    <span class="color">* </span>
                    <span>是否需要大会组委会提供食宿？</span>
                </div>
                <div class="chooseBox">
                    <div class="radio">
                        <span>是</span>
                        <div class="radioBox" :class="HotelType == 1 ? 'radioActive' : ''" @click="changeHotelType(1)"></div>
                    </div>
                    <div class="radio">
                        <span>否</span>
                        <div class="radioBox" :class="HotelType == 2 ? 'radioActive' : ''" @click="changeHotelType(2)"></div>
                    </div>
                </div>
            </div>
            <div class="hotel" v-if="HotelType == 1">
                <div class="hotelBox" v-for="(item , index) in HotelList" :key="index">
                    <div class="hotelName">{{ item.name }}</div>
                    <div class="cover">
                        <img :src="item.cover" alt="">
                    </div>
                    <div class="info">
                        <div class="introduceList">
                            <div class="introduce" v-for="(introduce , introduceIndex) in item.introduceList" :key="introduceIndex">
                                <div class="circle" ></div>
                                <span>{{ introduce }}</span>
                            </div>
                        </div>
                        <div class="count">
                            <span class="money"><span class="moneyNum">¥</span>{{ hotelMoneyCount(item) }}</span>
                            <div class="numberBox">
                                <div class="reduce" @click="changeHotelNumber(-1 , item)">
                                    <div class="line"></div>
                                </div>
                                <div class="number">{{ item.number }}</div>
                                <div class="add" @click="changeHotelNumber(1 , item)">
                                    <div class="line"></div>
                                    <div class="line"></div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="specsList">
                            <div class="specsBox" v-for="(specs , specsindex) in item.specs_list" :key="specsindex">
                                <span>{{ specs.specs_name }}</span>
                                <div class="radio" :class="item.specs_id == specs.specs_id ? 'radioActive' : ''" @click="setSpecs(item , specs)"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="notice">
                    <div class="line">
                        <span class="color">* </span>
                        <span> 注意事项：</span>
                    </div>
                    <div class="line"><span>（1）</span>入住时间最早为2024年2月5日14:00，退房时间最晚为2024年2月8日13:00。</div>
                    <div class="line"><span>（2）</span>此费用不含交通。后勤费并非强制，可自行预定周边其他酒店。</div>
                    <div class="line"><span>（3）</span>房间数量有限，先订先得。</div>
                </div>
            </div>

            <div class="isneed">
                <div class="desc">
                    <span class="color">* </span>
                    <span>是否参加会前集训？</span>
                </div>
                <div class="chooseBox">
                    <div class="radio">
                        <span>是</span>
                        <div class="radioBox" :class="themeType == 1 ? 'radioActive' : ''" @click="changethemeType(1)"></div>
                    </div>
                    <div class="radio">
                        <span>否</span>
                        <div class="radioBox" :class="themeType == 2 ? 'radioActive' : ''" @click="changethemeType(2)"></div>
                    </div>
                </div>
            </div>
            <div class="themeBox" v-if="themeType == 1">
                <div class="theme">
                    <div class="themeTitle">{{ TrainingList.name }}</div>
                    <div class="cover">
                        <img :src="TrainingList.cover" alt="">
                    </div>
                    <div class="expenseDetail">
                        <div class="detailBox" v-for="(item , index) in TrainingList.expenseDetail" :key="index">
                            <div class="circle"></div>
                            <div class="text">{{ item }}</div>
                        </div>
                    </div>
                    <div class="countBox">
                        <div class="money"><span class="moneyNum">¥</span>{{ TrainingMoneyCount() }}</div>
                        <div class="numberBox">
                            <div class="reduce" @click="changethemeNumber(-1)">
                                <div class="line"></div>
                            </div>
                            <div class="number">{{ TrainingList.number }}</div>
                            <div class="add" @click="changethemeNumber(1)">
                                <div class="line"></div>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                    <div class="time">
                        <span class="day">{{ TrainingList.startDate }}</span>
                        <div class="countDate">
                            <div class="line line1"></div>
                            <span>{{ TrainingList.countDate }}天</span>
                            <div class="line line2"></div>
                        </div>
                        <span class="day">{{ TrainingList.endDate }}</span>
                    </div>
                </div>
                <div class="notice">
                    <div class="line">
                        <span class="color">* </span>
                        <span>注意事项：</span>
                    </div>
                    <div class="line"><span>（1）</span>除特定的外出研学环节，其余的集训时间属于校园封闭式管理。</div>
                </div>
            </div>
        </div>

        <div class="refund">
            <div class="title">退费政策：</div>
            <div class="desc">
                <div>2024年1月1日23:59之前，可无条件提出退款；</div>
                <div>2024年1月5日23:59之前，可退60%；</div>
                <div>2024年1月10日23:59之前，可退30%；</div>
                <div>2024年1月15日00:00开始，不可退款；</div>
                <div>如因不可抗力取消，则费用全额退回。</div>
            </div>
            <div class="agreement">
                <span>用户协议：</span>
                <div class="link color">《用户协议》</div>
            </div>
            <div class="agree">
                <div class="radio" :class="aggrement ? 'radioActive' : ''" @click="aggrement = !aggrement"></div>
                <span>我已完全理解并同意上述退费政策和用户协议。</span>
            </div>
        </div>

        <div class="choose">
            <div class="title">已选：</div>
            <div class="list">
                <div class="tip">
                    <div class="left">
                        <div class="circle"></div>
                        <span>{{ CommitteeList.name }}</span>
                    </div>
                    <div class="money"><span class="moneyNum">¥</span>{{ assemblyType == -1 ? '0,000' : CommitteeList.price * this.form.team_number }}</div>
                </div>
                <template v-for="(item , index) in HotelList" >
                    <div class="tip" :key="index" v-if="item.number && item.price">
                        <div class="left">
                            <div class="circle"></div>
                            <span>{{ item.name }}</span>
                        </div>
                        <div class="money"><span class="moneyNum">¥</span>{{ item.number * item.price }}</div>
                    </div>
                </template>
                <div class="tip" v-if="themeType == 1">
                    <div class="left">
                        <div class="circle"></div>
                        <span>{{ TrainingList.name }}</span>
                    </div>
                    <div class="money"><span class="moneyNum">¥</span>{{ TrainingMoneyCount() }}</div>
                </div>
            </div>
            <div class="count">总计：<span class="moneyNum">¥</span>{{ countAllMoney() }}</div>
        </div>

        <div class="save">
            <div class="text">提交之前，请再次核对以上所填信息，确保信息无误~</div>
            <div class="buttonBox">
                <div class="saveButton" @click="save">保存</div>
                <div class="submit" @click="submit">提交并缴费</div>
            </div>
        </div>
    </div>
</template>
<script>
import {committee , grade , hotel , training} from './../../../api/api'
export default {
    data(){
        return {
            committeeNum : 0,
            aggrement : false,
            HotelType : -1,//是否选择食宿，1：是，2：否
            themeType : -1,//是否选择集训，1：是，2：否
            assemblyType : -1,//是否选择委员会，1：是，2：否
            GeadeName : '请选择',
            CommitteeList : {},
            gradeList : [],
            HotelList : [],
            TrainingList : {},
            gradeFlag : false,
            form : {
                uname : '',
                pinyin : '',
                mobile : '',
                code : '',
                idcard : '',
                school : '',
                mail : '',
                identity : '',
                team_number : '',
                theme : '',
                hotel : '',
                training : '',
            }
        }
    },
    mounted(){
        this.getCommitteeList()
        this.getGradeList()
        this.getTrainingList()
        this.getHotelList()
    },
    methods : {
        //代表团人数检测是否纯数字
        teamNumberTest(e){
            var isNum = /^\d+$/.test(e.target.value)
            if(!isNum && e.target.value.length != 0){
                this.form.team_number = 1
                e.target.value = 1
            }
        },
        save(){
            
        },
        submit(){
            if(!this.aggrement){
                this.$message.error('请勾选协议')
                return
            }
            if(this.HotelType == -1 || this.themeType == -1|| this.assemblyType == -1 ){
                this.$message.error('填完必填信息后，才能提交哦～')
                return
            }
            var hotelArr = []
            this.HotelList.forEach(item => {
                if(item.number && item.specs_id){
                    hotelArr.push(item.hotel_id + '_' + item.specs_id + '_' + item.number)
                }
            })
            this.form.hotel = hotelArr.join(',')
            this.form.training = this.TrainingList.train_id + '_' + this.TrainingList.number
            var themeArr = []
            this.CommitteeList.theme_list.forEach(item => {
                if(item.number > 0){
                    themeArr.push(item.theme_id + '_' + item.number)
                }
            })
            this.form.theme = themeArr.join(',')
            
            if(!this.form.uname || !this.form.pinyin || !this.form.mobile || !this.form.code || !this.form.idcard || !this.form.school  || !this.form.mail || !this.form.identity  || !this.form.team_number ){
                this.$message.error('填完必填信息后，才能提交哦～')
                return
            }
        },
        changeHotelNumber(value , item){
            if(value == -1 && item.number == 0) return
            if(item.rest_count && item.rest_count <= item.number) return
            item.number += value
        },
        changeNumber(value , item){
            if(value == -1 && item.number == 0) return
            if(item.rest_count && item.rest_count <= item.number && value == 1) return
            item.number += value
            var num = 0
            this.CommitteeList.theme_list.forEach(item => {
                num += item.number * 1
            })
            this.form.team_number = num
        },
        changethemeNumber(value){
            if(value == -1 && this.TrainingList.number == 0) return
            this.TrainingList.number += value
        },
        setSpecs(item , specs){
            if(item.specs_id == specs.specs_id){
                item.specs_id = ''
                item.price = ''
            }else{
                this.$set(item , 'specs_id' , specs.specs_id)
                this.$set(item , 'price' , specs.price)
            }
        },
        changethemeType(type){
            if(this.themeType == type){
                this.themeType = -1
                return
            }
            this.themeType = type
        },
        changeHotelType(type){
            if(this.HotelType == type){
                this.HotelType = -1
                return
            }
            this.HotelType = type
        },
        changeassemblyType(type){
            if(this.assemblyType == type){
                this.assemblyType = -1
                return
            }
            this.form.team_number = ''
            this.assemblyType = type
        },
        //选择大会
        chooseCommittee(item){
            if(item.rest_count == 0){
                return
            }
            item.theme_id_choose = !item.theme_id_choose
        },
        //选择身份
        chooseGender(value){
            this.form.identity = value
        },
        //集训营查询
        getTrainingList(){
            training().then(res => {
                if (res.data.code === '10000') {
                    res.data.data.expenseDetail = res.data.data.introduce.split(' ')
                    res.data.data.startDate = this.formatDate(res.data.data.start_date)
                    res.data.data.endDate = this.formatDate(res.data.data.end_date)
                    res.data.data.number = 0
                    this.TrainingList = res.data.data
                    // console.log(this.TrainingList)
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        //酒店列表
        getHotelList(){
            hotel().then(res => {
                if (res.data.code === '10000') {
                    res.data.data.forEach(item => {
                        item.introduceList = item.introduce.split(' ')
                        item.number = 0
                    })
                    this.HotelList = res.data.data
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        //委员会主题查询
        getCommitteeList(){
            committee().then(res => {
                if (res.data.code === '10000') {
                    res.data.data.theme_list.forEach(item => {
                        item.theme_name = JSON.parse(item.theme_name)
                        item.number = 0
                        // item.theme_id_choose = false
                    })
                    res.data.data.expenseDetail = res.data.data.expense_detail.split(' ')
                    res.data.data.startDate = this.formatDate(res.data.data.start_date)
                    res.data.data.endDate = this.formatDate(res.data.data.end_date)
                    res.data.data.countDate = Math.ceil((res.data.data.end_date - res.data.data.start_date) / 3600 / 24)
                    this.CommitteeList = res.data.data
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        //年级查询
        getGradeList(){
            grade().then(res => {
                if (res.data.code === '10000') {
                    this.gradeList = res.data.data
                } else {
                    this.$message.error(res.data.msg)
                }
            })
            
        },
        chooseGrade(item){
            this.gradeFlag = false
            this.form.grade_id = item.grade_id
            this.GeadeName = item.name
        },
        //时间处理
        formatDate(time) {
            var date=new Date(parseInt(time * 1000));
            var year=date.getFullYear();
            var mon = date.getMonth()+1;
            var day = date.getDate();
            mon = mon < 10 ? '0'+ mon : mon
            day = day < 10 ? '0'+ day : day
            return year+'年'+mon+'月'+day + '日';
        }
    },
    computed : {
        groupTypeFn(){
            return (item) => {
                var str = ''
                if(item.group_type == 1){
                    str =  '小学生 - '
                }else if(item.group_type == 2){
                    str = '中学生 - '
                }
                if(item.language == 1){
                    str +=  '汉语普通话'
                }else if(item.language == 2){
                    str += '英文'
                }
                return str
            }
        },
        activeList(){
            return (item) => {
                if(item.rest_count == 0){
                    return 'restNone'
                }
            }
        },
        hotelMoneyCount(){
            return (item) => {
                if(item.number && item.price){
                    return item.number * item.price
                }else{
                    return '0,000'
                }
            }
        },
        countAllMoney(){
            return () => {
                var money = 0
                if(this.assemblyType != -1){
                    money += this.CommitteeList.price * this.form.team_number
                }
                if(this.themeType == 1){
                    money += this.TrainingList.price * this.TrainingList.number
                }
                this.HotelList.forEach(item => {
                    if(item.number && item.price){
                        money += item.number * item.price
                    }
                })
                if(money == 0){
                    return '0,000'
                }else{
                    return money
                }
            }
        },
        TrainingMoneyCount(){
            return () => {
                if(this.TrainingList.number && this.TrainingList.price){
                    return this.TrainingList.number * this.TrainingList.price
                }else{
                    return '0,000'
                }
            }
        }
    }
}
</script>


<style lang="less" scoped>
.personRegister{
    // padding-top: 144px;
    padding-bottom: 71px;
    .save{
        margin: 80px auto 0;
        text-align: center;
        width: 288px;
        .text{
            font-size: 16px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 24px;
        }
        .buttonBox{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 32px;
            
        }
        .saveButton{
            width: 108px;
            height: 50px;
            background: #FFFFFF;
            border-radius: 8px;
            font-size: 18px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #000000;
            line-height: 50px;
            cursor: pointer;
        }
        .submit{
            width: 138px;
            height: 50px;
            background: #3DA6D5;
            border-radius: 8px;
            font-size: 18px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #FFFFFF;
            line-height: 50px;
            margin-left: 32px;
            cursor: pointer;
        }
    }
    .choose{
        width: 331px;
        background: #333333;
        border-radius: 8px;
        margin: 35px auto 0;
        overflow: hidden;
        padding-bottom: 16px;
        .list{
            width: 315px;
            background: #1C1C1C;
            border-radius: 8px;
            margin: 8px auto 0;
            padding: 16px 12px 16px 8px;
            .tip{
                margin-top: 24px;
                font-size: 14px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 20px;
                display: flex;
                justify-content: space-between;
                .left{
                    display: flex;
                    align-items: center;
                    .circle{
                        width: 9px;
                        height: 9px;
                        background: #3DA6D5;
                        border-radius: 50%;
                        margin-right: 4px;
                    }
                }
            }
            .tip:nth-of-type(1){
                margin-top: 0;
            }
        }
        .count{
            font-size: 32px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 42px;
            margin-top: 16px;
            text-align: right;
            margin-right: 8px;
        }
        .title{
            font-size: 20px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #FFFFFF;
            line-height: 28px;
            margin-top: 16px;
            margin-left: 32px;
        }
    }
    .refund{
        width: 331px;
        margin: 48px auto 0;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        padding: 15px 0;
        .agree{
            display: flex;
            align-items: center;
            margin-top: 24px;
            span{
                font-size: 14px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #D1D1D1;
                line-height: 20px;
                margin-left: 14px;
            }
            .radio{
                width: 24px;
                height: 24px;
                border-radius: 8px;
                border: 1px solid #FFFFFF;
            }
        }
        .title{
            font-size: 16px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #D1D1D1;
            line-height: 22px;
        }
        .desc{
            font-size: 14px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #D1D1D1;
            line-height: 20px;
            margin-top: 11px;
        }
        .agreement{
            font-size: 16px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #D1D1D1;
            line-height: 22px;
            margin-top: 24px;
            display: flex;
            .link{
                width: 95px;
                height: 22px;
                font-size: 16px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #3DA6D5;
                line-height: 22px;
                border-bottom: 1px solid #3DA6D5;
            }
        }
    }
    .stay{
        width: 331px;
        margin: 48px auto 0;
        .theme{
            width: 331px;
            height: 613px;
            background: #333333;
            border-radius: 8px;
            margin: 24px auto 0;
            overflow: hidden;
            .countBox{
                width: 315px;
                margin: 40px auto 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .money{
                    font-size: 32px;
                    font-family: YouSheBiaoTiHei;
                    color: #FFFFFF;
                    line-height: 42px;
                    margin-left: 4px;
                    text-align: center;
                }
                .numberBox{
                    width: 113px;
                    height: 36px;
                    background: #1C1C1C;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 8px;
                    .reduce{
                        width: 30px;
                        height: 30px;
                        border-radius: 4px;
                        border: 1px solid #333333;
                        position: relative;
                        cursor: pointer;
                    }
                    .add{
                        width: 30px;
                        height: 30px;
                        background: #333333;
                        border-radius: 4px;
                        position: relative;
                        cursor: pointer;
                    }
                    .number{
                        font-size: 18px;
                        font-family: AlibabaPuHuiTi_2_55_Regular;
                        color: #D1D1D1;
                        line-height: 36px;
                        width: 47px;
                        text-align: center;
                    }
                    .line{
                        width: 12px;
                        height: 2px;
                        background: #FFFFFF;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        margin-left: -6px;
                        margin-top: -1px;
                    }
                    .line:nth-of-type(2){
                        transform: rotate(90deg);
                    }
                }
            }
            .expenseDetail{
                width: 299px;
                margin: 24px auto 0;
                .detailBox{
                    display: flex;
                    margin-top: 16px;
                    .circle{
                        width: 9px;
                        height: 9px;
                        background: #AAAAAA;
                        border-radius: 50%;
                        margin-top: 5px;
                    }
                    .text{
                        font-size: 18px;
                        font-family: AlibabaPuHuiTi_2_55_Regular;
                        color: #AAAAAA;
                        line-height: 20px;
                        margin-left: 8px;
                    }
                }
            }
            .time{
                width: 315px;
                height: 36px;
                background: #1C1C1C;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 16px auto 0;
                .countDate{
                    width: 33px;
                    height: 18px;
                    background: #1C1C1C;
                    border-radius: 9px;
                    border: 1px solid #979797;
                    text-align: center;
                    position: relative;
                    margin: 0 9px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span{
                        font-size: 12px;
                        font-family: AlibabaPuHuiTi_2_55_Regular;
                        color: #FFFFFF;
                        line-height: 16px;
                    }
                    .line{
                        position: absolute;
                        width: 3px;
                        height: 1px;
                        border: 1px solid #979797;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    .line1{
                        left: -4px;
                    }
                    .line2{
                        right: -4px;
                    }
                }
                
                .day{
                    font-size: 16px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #FFFFFF;
                    line-height: 22px;
                }
            }
            
            .themeTitle{
                font-size: 24px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                line-height: 40px;
                margin: 16px auto 0;
                text-align: center;
            }
            .cover{
                width: 299px;
                height: 224px;
                margin: 16px auto 0;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .hotel{
            .hotelBox{
                width: 331px;
                // height: 595px;
                padding-bottom: 12px;
                background: #333333;
                border-radius: 8px;
                margin: 24px auto 0;
                overflow: hidden;
                .specsList{
                    width: 300px;
                    height: 77px;
                    background: #1C1C1C;
                    border-radius: 8px;
                    margin: 12px auto 0;
                    display: flex;
                    flex-wrap: wrap;
                    .specsBox{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 132px;
                        .radio{
                            width: 20px;
                            height: 20px;
                            border-radius: 4px;
                            border: 1px solid #FFFFFF;
                        }
                        span{
                            font-size: 16px;
                            font-family: AlibabaPuHuiTi_2_55_Regular;
                            color: #FFFFFF;
                            line-height: 26px;
                            margin-left: 16px;
                        }
                    }
                    .specsBox:nth-of-type(2n){
                        margin-left: 19px;
                    }
                }
                .count{
                    display: flex;
                    width: 299px;
                    margin: 43px auto 0;
                    align-items: center;
                    justify-content: space-between;
                    .money{
                        font-size: 32px;
                        font-family: YouSheBiaoTiHei;
                        color: #FFFFFF;
                        line-height: 42px;
                    }
                    .numberBox{
                        width: 113px;
                        height: 36px;
                        background: #1C1C1C;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .reduce{
                            width: 30px;
                            height: 30px;
                            border-radius: 4px;
                            border: 1px solid #333333;
                            position: relative;
                            cursor: pointer;
                        }
                        .add{
                            width: 30px;
                            height: 30px;
                            background: #333333;
                            border-radius: 4px;
                            position: relative;
                            cursor: pointer;
                        }
                        .number{
                            font-size: 18px;
                            font-family: AlibabaPuHuiTi_2_55_Regular;
                            color: #D1D1D1;
                            line-height: 36px;
                            width: 47px;
                            text-align: center;
                        }
                        .line{
                            width: 12px;
                            height: 2px;
                            background: #FFFFFF;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            margin-left: -6px;
                            margin-top: -1px;
                        }
                        .line:nth-of-type(2){
                            transform: rotate(90deg);
                        }
                    }
                }
                .info{
                    .introduceList{
                        width: 299px;
                        margin: 24px auto 0;
                        .introduce{
                            display: flex;
                            align-items: center;
                            margin-top: 16px;
                            .circle{
                                width: 9px;
                                height: 9px;
                                background: #AAAAAA;
                                border-radius: 50%;
                                margin-right: 8px;
                            }
                            span{
                                font-size: 18px;
                                font-family: AlibabaPuHuiTi_2_55_Regular;
                                color: #AAAAAA;
                                line-height: 20px;
                            }
                        }
                    }
                }
                .hotelName{
                    font-size: 24px;
                    font-family: YouSheBiaoTiHei;
                    color: #FFFFFF;
                    line-height: 40px;
                    margin: 16px auto 0;
                    text-align: center;
                }
                .cover{
                    width: 299px;
                    height: 224px;
                    margin: 16px auto 0;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .notice{
            font-size: 16px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #AAAAAA;
            line-height: 22px;
            margin-top: 16px;
            .line{
                margin-top: 8px;
                display: flex;
            }
        }
        .isneed{
            margin-top: 24px;
            .chooseBox{
                display: flex;
                align-items: center;
                margin-top: 12px;
                .radio{
                    width: 158px;
                    height: 40px;
                    background: rgba(216,216,216,0.3);
                    border-radius: 8px;
                    border: 1px solid #FFFFFF;
                    font-size: 14px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #FFFFFF;
                    line-height: 38px;
                    text-indent: 15px;
                    position: relative;
                    .radioBox{
                        width: 24px;
                        height: 24px;
                        border-radius: 8px;
                        border: 1px solid #FFFFFF;
                        position: absolute;
                        right: 12px;
                        top: 50%;
                        margin-top: -12px;
                    }
                }
                .radio:nth-of-type(2){
                    margin-left: 14px;
                }
            }
            .desc{
                font-size: 14px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #fff;
                line-height: 21px;
                
            }
        }
        .title{
            font-size: 24px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 31px;
            text-align: center;
        }
    }
    .assemblyInfo{
        width: 331px;
        margin: 16px auto 0;
        .notice{
            font-size: 12px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #AAAAAA;
            line-height: 17px;
            margin-top: 16px;
            .text{
                margin-top: 8px;
            }
        }
        .theme{
            width: 331px;
            // height: 510px;
            padding-bottom: 8px;
            background: #333333;
            border-radius: 8px;
            margin: 24px auto 0;
            text-align: center;
            overflow: hidden;
            .themeTitle{
                font-size: 24px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                line-height: 40px;
                margin: 16px auto 0;
            }
            .info{
                .expenseDetail{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin-top: 29px;
                    margin-left:25px;
                    .detailBox{
                        display: flex;
                        align-items: center;
                        // justify-content: center;
                        width: 142px;
                        margin-top: 16px;
                        .circle{
                            width: 9px;
                            height: 9px;
                            background: #AAAAAA;
                            border-radius: 50%;
                        }
                        .text{
                            font-size: 18px;
                            font-family: AlibabaPuHuiTi_2_55_Regular;
                            color: #AAAAAA;
                            line-height: 20px;
                            margin-left: 8px;
                        }
                    }
                    .detailBox:nth-of-type(1){
                        margin-top: 0;
                    }
                    .detailBox:nth-of-type(2){
                        margin-top: 0;
                    }
                }
                .price{
                    font-size: 32px;
                    font-family: YouSheBiaoTiHei;
                    color: #FFFFFF;
                    line-height: 42px;
                    margin-top: 32px;
                }
                .time{
                    width: 315px;
                    height: 36px;
                    background: #1C1C1C;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 16px auto 0;
                    .countDate{
                        width: 33px;
                        height: 18px;
                        background: #1C1C1C;
                        border-radius: 9px;
                        border: 1px solid #979797;
                        text-align: center;
                        position: relative;
                        margin: 0 9px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        span{
                            font-size: 12px;
                            font-family: AlibabaPuHuiTi_2_55_Regular;
                            color: #FFFFFF;
                            line-height: 16px;
                        }
                        .line{
                            position: absolute;
                            width: 3px;
                            height: 1px;
                            border: 1px solid #979797;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        .line1{
                            left: -4px;
                        }
                        .line2{
                            right: -4px;
                        }
                    }
                    
                    .day{
                        font-size: 16px;
                        font-family: AlibabaPuHuiTi_2_55_Regular;
                        color: #FFFFFF;
                        line-height: 22px;
                    }
                }
            }
            .moneyInfo{
                font-size: 24px;
                font-family: YouSheBiaoTiHei;
                color: #fff;
                line-height: 31px;
                margin-top: 24px;
            }
            .countMoney{
                font-size: 32px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                line-height: 45px;
                margin-top: 6px;
            }
            .cover{
                width: 299px;
                height: 224px;
                margin: 16px auto 0;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .list{
            .wrapper{
                margin: 24px auto 0;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                img{
                    width: 100%;
                    height: 186px;
                }
            }
            .wrapper:nth-of-type(1){
                margin: 12px auto 0;
            }
            .radio{
                width: 16px;
                height: 16px;
                background: #1C1C1C;
                border: 2px solid #FFFFFF;
                position: absolute;
                top: 18px;
                left: 15px;
                border-radius: 50%;
                cursor: pointer;
            }
            .numberBox{
                width: 113px;
                height: 36px;
                background: #1C1C1C;
                border-radius: 8px;
                border: 1px solid #979797;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 8px;
                .reduce{
                    width: 30px;
                    height: 30px;
                    border-radius: 4px;
                    border: 1px solid #333333;
                    position: relative;
                    cursor: pointer;
                }
                .add{
                    width: 30px;
                    height: 30px;
                    background: #333333;
                    border-radius: 4px;
                    position: relative;
                    cursor: pointer;
                }
                .number{
                    font-size: 18px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #D1D1D1;
                    line-height: 36px;
                    width: 47px;
                    text-align: center;
                }
                .line{
                    width: 12px;
                    height: 2px;
                    background: #FFFFFF;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin-left: -6px;
                    margin-top: -1px;
                }
                .line:nth-of-type(2){
                    transform: rotate(90deg);
                }
            }
            .listBox{
                padding: 16px 0;
                background: rgba(216,216,216,0.3);
                border-radius: 8px;
                border: 1px solid #FFFFFF;
                width: 100%;
                position: relative;
                margin-top: 12px;
                .restCount{
                    position: absolute;
                    font-size: 14px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #FFFFFF;
                    line-height: 22px;
                    right: 24px;
                    bottom: 16px;
                }
                .groupType{
                    font-size: 14px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    line-height: 22px;
                    margin-top: 12px;
                    margin-left: 40px;
                }
                .line1{
                    font-size: 16px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #FFFFFF;
                    line-height: 22px;
                    margin-left: 40px;
                }
                .themeList{
                    margin-top: 12px;
                    font-size: 14px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #D1D1D1;
                    line-height: 22px;
                    margin-left: 40px;
                    display: flex;
                    .themeName{
                        width: 205px;
                    }
                }
            }
            .listBoxActive{
                background: #1C1C1C;
                border: 1px solid #FFFFFF;
            }
            .restNone{
                background: rgba(255,255,255,0.1);
                border: 1px solid #777777;
                .radio{
                    border-color: #777777;
                }
                .line1{
                    color: #777777;
                    .color{
                        color: #777777;
                    }
                }
                .themeList{
                    color: #777777;
                }
                .restCount{
                    color: #777777;
                }
            }
        }
        .title{
            font-size: 24px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 31px;
            text-align: center;
        }
        .desc{
            margin-top: 24px;
            .text{
                font-size: 14px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #fff;
                line-height: 21px;
            }
            .text1{
                font-size: 12px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #777777;
                line-height: 17px;
                margin-left: 18px;
                margin-top: 24px;
                display: flex;
                .color{
                    margin-right: 3px;
                }
            }
        }
        .isneed{
            margin-top: 24px;
            .chooseBox{
                display: flex;
                align-items: center;
                margin-top: 12px;
                .radio{
                    width: 158px;
                    height: 40px;
                    background: rgba(216,216,216,0.3);
                    border-radius: 8px;
                    border: 1px solid #FFFFFF;
                    font-size: 14px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #FFFFFF;
                    line-height: 38px;
                    text-indent: 15px;
                    position: relative;
                    .radioBox{
                        width: 24px;
                        height: 24px;
                        border-radius: 8px;
                        border: 1px solid #FFFFFF;
                        position: absolute;
                        right: 12px;
                        top: 50%;
                        margin-top: -12px;
                    }
                }
                .radio:nth-of-type(2){
                    margin-left: 14px;
                }
            }
            .desc{
                font-size: 14px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #fff;
                line-height: 21px;
            }
        }
        .formItem{
            margin-top: 24px;
            .inputBox{
                margin-top: 12px;
                position: relative;
                input{
                    width: 100%;
                    height: 40px;
                    background: rgba(216,216,216,0.3);
                    border-radius: 8px;
                    border: 1px solid #FFFFFF;
                    outline: none;
                    text-indent: 15px;
                    font-size: 14px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #FFFFFF;
                    line-height: 38px;
                }
                .sms{
                    font-size: 16px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #1C1C1C;
                    line-height: 30px;
                    width: 95px;
                    height: 30px;
                    background: #D8D8D8;
                    border-radius: 4px;
                    position: absolute;
                    right: 5px;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: center;
                    cursor: pointer;
                }
                .phoneBox{
                    position: absolute;
                    border-right: 1px solid #979797;
                    padding-right: 12px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    img{
                        width: 21px;
                        height: 26px;
                    }
                }
            }
            .phoneInputBox{
                input{
                    text-indent: 62px;
                }
            }
            .tip{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .tipName{
                    font-size: 14px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #fff;
                    line-height: 21px;
                }
                
            }
            .desc{
                font-size: 12px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #777777;
                line-height: 17px;
                text-indent: 8px;
                margin-top: 6px;
            }
        }
    }
    .personInfo{
        width: 331px;
        margin: 36px auto 0;
        .form{
            .formItem{
                margin-top: 24px;
                .inputBox{
                    margin-top: 12px;
                    position: relative;
                    input{
                        width: 100%;
                        height: 40px;
                        background: rgba(216,216,216,0.3);
                        border-radius: 8px;
                        border: 1px solid #FFFFFF;
                        outline: none;
                        text-indent: 15px;
                        font-size: 14px;
                        font-family: AlibabaPuHuiTi_2_55_Regular;
                        color: #FFFFFF;
                        line-height: 38px;
                    }
                    .sms{
                        font-size: 16px;
                        font-family: AlibabaPuHuiTi_2_55_Regular;
                        color: #1C1C1C;
                        line-height: 30px;
                        width: 95px;
                        height: 30px;
                        background: #D8D8D8;
                        border-radius: 4px;
                        position: absolute;
                        right: 5px;
                        top: 50%;
                        transform: translateY(-50%);
                        text-align: center;
                        cursor: pointer;
                    }
                    .phoneBox{
                        position: absolute;
                        border-right: 1px solid #979797;
                        padding-right: 12px;
                        height: 28px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        left: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                        img{
                            width: 21px;
                            height: 26px;
                        }
                    }
                }
                .phoneInputBox{
                    input{
                        text-indent: 62px;
                    }
                }
                .tip{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .tipName{
                        font-size: 14px;
                        font-family: AlibabaPuHuiTi_2_55_Regular;
                        color: #fff;
                        line-height: 21px;
                    }
                    
                }
                .desc{
                    font-size: 12px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #777777;
                    line-height: 17px;
                    text-indent: 8px;
                    margin-top: 6px;
                }
            }
            .double{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .Box{
                    width: 100%;
                    .inputBoxWrapper{
                        height: 40px;
                        background: rgba(216,216,216,0.3);
                        border-radius: 8px;
                        border: 1px solid #FFFFFF;
                        font-size: 14px;
                        font-family: AlibabaPuHuiTi_2_55_Regular;
                        color: #FFFFFF;
                        line-height: 38px;
                        text-indent: 8px;
                        position: relative;
                        margin-top: 12px;
                        .showList{
                            width: 12px;
                            height: 8px;
                            position: absolute;
                            right: 12px;
                            top: 50%;
                            margin-top: -4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .gradeList{
                            width: 100%;
                            height: 180px;
                            background: #000000;
                            border-radius: 10px;
                            border: 1px solid #FFFFFF;
                            overflow: auto;
                            position: absolute;
                            z-index: 99;
                            top: 40px;
                            left: 0;
                            scrollbar-color: #fff transparent; 
		                    scrollbar-width: thin; 
                            .grade{
                                height: 45px;
                                font-size: 16px;
                                font-family: AlibabaPuHuiTi_2_55_Regular;
                                color: #FFFFFF;
                                line-height: 45px;
                                text-align: center;
                                cursor: pointer;
                            }
                            .grade:active{
                                background: #3DA6D5;
                            }
                        }
                        ::-webkit-scrollbar {/*滚动条整体样式*/
                            width: 8px;
                            background: transparent;
                            border-radius: 9px;
                        }
                        ::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
                            background: #fff;
                            border-radius: 9px;
                        }
                    }
                    .radioBox{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 12px;
                        .radioItem{
                            width: 158px;
                            height: 40px;
                            background: rgba(216,216,216,0.3);
                            border-radius: 8px;
                            border: 1px solid #FFFFFF;
                            font-size: 14px;
                            font-family: AlibabaPuHuiTi_2_55_Regular;
                            color: #FFFFFF;
                            line-height: 38px;
                            text-indent: 15px;
                            position: relative;
                            .radio{
                                width: 24px;
                                height: 24px;
                                border-radius: 8px;
                                border: 1px solid #FFFFFF;
                                position: absolute;
                                right: 11px;
                                top: 50%;
                                margin-top: -12px;
                            }
                        }
                    }
                }
            }
        }
        .title{
            font-size: 24px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 31px;
            text-align: center;
        }
    }
}
</style>