let OSS = require('ali-oss');
let client = new OSS({
	region: 'oss-cn-shenzhen',
	accessKeyId: 'LTAI5tHK65APFSkav1UKK4xQ',
	accessKeySecret: 'xsOB7Ayscgy3iJHfcNmyr7zYWgXo5w',
	bucket: 'gemun',
	Endpoint: 'https://gemun.oss-cn-shenzhen.aliyuncs.com',
	secure: true
})
//查看bucket列表
export async function listBuckets() {
	try {
		let result = await client.listBuckets();
		console.log(result)
	} catch (err) {
		console.log(err)
	}
}

//上传文件
// client.useBucket('tiniuniu');
export async function put(file, processStr) {
	let time = new Date().getTime()
	// console.log(file)
	var type = file.type
	var index = file.name.indexOf('.')
	if (type.indexOf('image') > -1) {
		var name = 'png'
	} else if (type.indexOf('video') > -1) {
		var name = 'mp4'
	}else if (type.indexOf('pdf') > -1) {
		var name = 'pdf'
	}
	var fileName = file.name.substr(0, index) + time + '.' + name
	var reg = /[\u4e00-\u9fa5]/g;
	fileName = fileName.replace(reg, "") //去除中文
	fileName = fileName.replace(/\s*/g, "") //去除空格
	try {
		let result = await client.put(fileName, new Blob([file]), {
			meta: {
				'ContentType': type,
				'ContentDisposition': 'inline'
			}
		});
		//打印水印
		if (processStr) {
			var obj = {}
			obj = processImage(fileName, processStr)
			return obj
		}
		const url = client.signatureUrl(fileName, {
			expires: 31536000, // 设置过期时间，默认为1800秒。
		});
		return { 'url': url, 'name': result.name }
	} catch (e) {
		alert(e);
	}
}
//带水印图片
export async function processImage(sourceImage, processStr) {
	const targetImage = 'waterMark' + sourceImage
	const targetBucket = 'tiniuniu'
	const result = await client.processObjectSave(
		sourceImage,
		targetImage,
		processStr,
		targetBucket
	);
	const url = client.signatureUrl(targetImage, {
		expires: 86400, // 设置过期时间，默认为1800秒。
	});
	return { 'url': url, 'name': targetImage }
}
//删除图片
export async function deleteFile(fileName) {
	try {
		// 填写Object完整路径。Object完整路径中不能包含Bucket名称。
		let result = await client.delete(fileName);
		console.log(result);
	} catch (e) {
		console.log(e);
	}
}