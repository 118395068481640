<template>
    <div class="weiyuan">
        <div class="MvideoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ $store.state.language.type == 1 ? data.title : data.title_en }}</div>
            <div class="title2">{{ $store.state.language.type == 1 ? data.describe : data.describe_en }}</div>
            <video :src="data.url" ref="video" autoplay loop muted webkit-playsinline="true" playsinline="true" ></video>
        </div>
        <div class="content">
            <div class="Box firstBox">
                <div class="wrapper"  @touchstart="touchstartFn1" @touchend="touchendFn1" >
                    <div class="dots">
                        <div :class="Current1 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in 1"   :key="index"></div>
                    </div>
                    <el-carousel   ref="carousel1"  class="carousel" arrow="never" :interval="10000"   indicator-position="none">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="wrappercontent">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/weiyuanOne' + item + '.png' " alt="" class="img">
                                
                                
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="info">
                    <div class="infoTitle">裁军与国际安全委员会</div>
                    <div class="text firstText">议题：减少核武器、化学武器的存量问题</div>
                    <div class="text">会场语言：中文</div>
                    <div class="desc">
                        议题简介：核武器和化学武器是大规模杀伤性武器（Weapons of Mass Destruction, WMD）的重要组成部分，对全球和平与安全构成严重威胁。减少这些武器的存量是国际社会长期关注和努力的焦点，也是联合国第一委员会（裁军与国际安全委员会）的重要议题之一。
                    </div>
                </div>
            </div>
            <div class="Box">
                <div class="wrapper"  @touchstart="touchstartFn1" @touchend="touchendFn1" >
                    <div class="dots">
                        <div :class="Current1 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in 1"   :key="index"></div>
                    </div>
                    <el-carousel   ref="carousel1"  class="carousel" arrow="never" :interval="10000"   indicator-position="none">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="wrappercontent">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/weiyuanTwo' + item + '.png' " alt="" class="img">
                                
                                
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                
                <div class="info">
                    <div class="infoTitle">United Nations Security Council</div>
                    <div class="text firstText">议题：Reducing the Stockpile of Chemical and Nuclear Weapons</div>
                    <div class="text">会场语言：英文</div>
                    <div class="desc">
                        议题简介：Nuclear weapons and chemical weapons are crucial components of Weapons of Mass Destruction (WMD), posing significant threats to global peace and security. Reducing the stockpiles of these weapons has been a long-standing focus of international attention and efforts.
                    </div>
                    
                </div>
            </div>
            <div class="Box">
                <div class="wrapper"  @touchstart="touchstartFn1" @touchend="touchendFn1" >
                    <div class="dots">
                        <div :class="Current1 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in 1"   :key="index"></div>
                    </div>
                    <el-carousel   ref="carousel1"  class="carousel" arrow="never" :interval="10000"   indicator-position="none">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="wrappercontent">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/weiyuanThree' + item + '.png' " alt="" class="img">
                                
                                
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="info">
                    <div class="infoTitle">联合国安全理事会</div>
                    <div class="text firstText">议题：为巴以冲突寻求永久解决方案</div>
                    <div class="text">会场语言：中文</div>
                    <div class="desc">
                        议题简介：自20世纪中期以来，巴勒斯坦和以色列之间的冲突一直是中东地区最复杂、最持久的问题之一。这场冲突涉及领土、安全、人道主义和政治问题，深刻影响着该地区的稳定和和平。自2023年10月7日新一轮巴以冲突爆发以来，截止2024年6月15日，以军在加沙地带的军事行动已致37296名巴勒斯坦人死亡，85197人受伤，为巴以冲突寻求永久解决方案刻不容缓。
                    </div>
                    
                </div>
            </div>
            <div class="Box firstBox">
                <div class="wrapper"  @touchstart="touchstartFn1" @touchend="touchendFn1" >
                    <div class="dots">
                        <div :class="Current1 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in 1"   :key="index"></div>
                    </div>
                    <el-carousel   ref="carousel1"  class="carousel" arrow="never" :interval="10000"   indicator-position="none">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="wrappercontent">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/weiyuanFour' + item + '.png' " alt="" class="img">
                                
                                
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="info">
                    <div class="infoTitle">United Nations Security Council</div>
                    <div class="text firstText">议题：Permanent Solutions to the Israeli-Palestinian Conflict</div>
                    <div class="text">会场语言：英文</div>
                    <div class="desc">
                        议题简介：Since the mid-20th century, the conflict between Palestine and Israel has been one of the most complex and enduring issues in the Middle East. This conflict involves territorial, security, humanitarian, and political issues, deeply impacting the stability and peace of the region. Since the outbreak of the latest round of Israeli-Palestinian conflict on October 7, 2023, as of June 15, 2024, Israeli military actions in the Gaza Strip have resulted in 37,296 Palestinian fatalities and 85,197 injuries, underscoring the urgent need for a permanent solution to the Israeli-Palestinian conflict.
                    </div>
                    
                </div>
            </div>
            <div class="Box">
                <div class="wrapper"  @touchstart="touchstartFn1" @touchend="touchendFn1" >
                    <div class="dots">
                        <div :class="Current1 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in 1"   :key="index"></div>
                    </div>
                    <el-carousel   ref="carousel1"  class="carousel" arrow="never" :interval="10000"   indicator-position="none">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="wrappercontent">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/weiyuanFive' + item + '.png' " alt="" class="img">
                                
                                
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="info">
                    <div class="infoTitle">社会、人道主义和文化委员会</div>
                    <div class="text firstText">议题：如何保障巴以争议地区的人道主义援助</div>
                    <div class="text">会场语言：中文</div>
                    <div class="desc">
                        议题简介：确保巴以冲突地区的人道主义援助至关重要。首先，冲突导致大量平民失去家园和生计，无法获得基本生活必需品如食物、水、医疗服务和住所。其次，如何保护弱势群体，包括儿童、妇女、老年人和残疾人，确保他们的安全和健康。再者，如何维护基本人权、以及如何促进长期和平与稳定都需要代表们给出建设性和可操作性方案。
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {config} from './../../../utils/config'
import {banner} from './../../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                url : '',
            },
            load : false,
            configData : {},
            Current1 : 0,
            startMove1 : 0,
        }
    },
    beforeMount(){
        if(this.$store.state.language.type == 1){
            this.configData = config.weiyuan.chinese
            this.configData = config.weiyuan.english
        }else{
            this.configData = config.weiyuan.english
        }
    },
    mounted(){
        
        banner({'seat' : 7,'device': 2}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    watch : {
		"$store.state.language" : function (){
			if(this.$store.state.language.type == 1){
				this.configData = config.weiyuan.chinese
			}else{
				this.configData = config.weiyuan.english
			}
		}
	},
    methods : {
        //手机滑动切换
        touchstartFn1(e){
            this.startMove1 = e.changedTouches[0].clientX
        },
        touchendFn1(e){
            if(e.changedTouches[0].clientX - this.startMove1 > 50 ){
                this.changePage1(-1)
            }else if(e.changedTouches[0].clientX - this.startMove1 < -50){
                this.changePage1(1)
            }
        },
        changePage1(page){
            if(page > 0){
                this.$refs.carousel1.next()
            }else{
                this.$refs.carousel1.prev()
            }
        },
    }
}
</script>

<style lang="less" scoped>
.weiyuan{
    padding-bottom: 50px;
}
.content{
    width: 331px;
    margin: 0 auto;
    .Box{
        margin-top: 24px;
        .wrapper{
            width: 100%;
            position: relative;
            /deep/ .el-carousel__container{
                height: 160px;
            }
            .wrappercontent{
                height: 100%;
                cursor: pointer;
                .img{
                    height: 100%;
                    width: 100%;
                }
                .jianjie{
                    width: 113px;
                    height: 48px;
                    background: rgba(0,0,0,0.2);
                    border-radius: 8px;
                    border: 1px solid #FFFFFF;
                    font-size: 24px;
                    font-family: YouSheBiaoTiHei;
                    color: #FFFFFF;
                    line-height: 46px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50% , -50%);
                    text-align: center;
                    z-index: 1;
                }
                .model{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
            // .wrappercontent:active{
            //     .jianjie{
            //         background: #3DA6D5;
            //         border-color: #3DA6D5;
            //     }
            //     .model{
            //         background: rgba(0, 0, 0, 0.39);
            //     }
            // }
        }
        .info{
            margin-top: 30px;
            .infoTitle{
                font-size: 18px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 32px;
            }
            .text{
                font-size: 14px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 32px;
                margin-top: 4px;
            }
            .firstText{
                margin-top: 8px;
            }
            .desc{
                margin-top: 8px;
                font-size: 14px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #AAAAAA;
                line-height: 32px;
            }
            .getMore{
                font-size: 14px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #3DA6D5;
                line-height: 32px;
            }
        }
    }
    // .firstBox{
    //     margin-top: 16px;
    // }
    .title{
        font-size: 24px;
        font-family: YouSheBiaoTiHei;
        color: #FFFFFF;
        line-height: 31px;
        margin-top: 24px;
        text-align: center;
    }
}
.videoBox{
    video{
        top: unset;
        bottom: 0;
    }
}
</style>