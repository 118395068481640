<template>
    <div class="Mabout">
        <div class="MvideoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ data.title }}</div>
            <div class="title2">{{ data.describe }}</div>
            <video :src="data.url" ref="video" autoplay loop muted webkit-playsinline="true" playsinline="true"></video>
        </div>

        <div class="content">
            <div class="ted">
                <div class="title">什么是BetterWorldMUN？ </div>
                <div class="info">
                    <div class="img">
                        <img src="./../../assets/img/about1.png" alt="">
                    </div>
                    <div class="text">
                        <span class="color">BetterWorld全球青少年模拟联合国大会</span>（BetterWorldMUN），是一个专为4-12年级学生举办的为期四天的国际关系模拟活动，地点在中国海南省三亚市。在<span class="color">BetterWorldMUN</span>，代表们通过扮演联合国代表以及其他国际机构和国家内阁成员的角色，深入研究国际事务、联合国运作模式、公共演讲和外交技巧。会议期间，代表们将了解平衡国家利益与国际社会需求的重要性，就世界领导人面临的日常问题进行辩论，并最终起草相应的解决方案。
                    </div>
                </div>
            </div>
            <div class="ted">
                <div class="title">BetterWorldMUN的愿景</div>
                <div class="info">
                    <div class="img">
                        <img src="./../../assets/img/about2.png" alt="">
                    </div>
                    <div class="text">
                        秉承联合国的精神，<span class="color">BetterWorldMUN</span>专注于打造一个建设性论坛，就一系列国际问题进行公开对话。我们通过模拟当前、历史和未来的重大事件，营造一个吸引未来外交官、政治家、企业家参与的环境。我们鼓励代表们批判性地看待周围的世界，并以创新性的方式思考，年轻人可以做些什么来影响自己的社区和整个世界的变化。
                    </div>
                </div>
            </div>
            <div class="ted">
                <div class="title">为什么选择BetterWorldMUN？</div>
                <div class="info">
                    <div class="img">
                        <img src="./../../assets/img/about3.png" alt="">
                    </div>
                    <div class="text">
                        <span class="color">BetterWorldMUN</span>
                        致力于为来自世界各地的代表提供丰富的学术辩论并培养其领导力，让代表们辩论当今世界面临的最紧迫问题，并起草创新、创造性的解决方案。参与者将在整个过程中培养多种技能，包括但不限于：公开演讲、创造性规划、批判性思维、谈判、团队合作、领导力和政策制定。
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {banner} from './../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                video_url : '',
            },
            load : false
        }
    },
    mounted(){
        banner({'seat' : 5,'device': 2}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                setTimeout(() => {
                    this.load = true
                } , 800)
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
}
</script>

<style lang="less" scoped>
.Mabout{
    background: #1C1C1C;
    text-align: center;
    padding-bottom: 50px;
    .content{
        .ted{
            margin: 64px auto 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 331px;
            .title{
                font-size: 18px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                line-height: 23px;
            }
            img{
                width: 100%;
                height: 160px;
                display: block;
                margin-top: 16px;
            }
            .text{
                font-size: 14px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #D1D1D1;
                line-height: 24px;
                margin-top: 16px;
                text-align: left;
                width: 331px;
                word-break: break-all;
                .color{
                    color: #3DA6D5;
                }
            }
        }
        .ted:nth-of-type(1){
            margin-top: 48px;
        }
    }
    .team{
        margin-top: 24px;
        .title{
            font-size: 26px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 31px;
        }
        img{
            width: 241px;
            height: 240px;
            margin-top: 24px;
            margin-bottom: 24px;
        }
        .name{
            font-size: 24px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 40px;
        }
        .info{
            font-size: 12px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            font-weight: 400;
            color: #B6B6B6;
            line-height: 25px;
            margin-top: 8px;
        }
    }
    .MvideoBox{
        // background-image: url('./../../assets/img/aboutBanner.png');
        video{
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
</style>