<template>
    <div class="food">
        <div class="MvideoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ $store.state.language.type == 1 ? data.title : data.title_en }}</div>
            <div class="title2">{{ $store.state.language.type == 1 ? data.describe : data.describe_en }}</div>
            <video :src="data.url" ref="video" autoplay loop muted webkit-playsinline="true" playsinline="true" ></video>
        </div>
        <div class="typeBox typeBox1">
            <div class="type">
                <span>住宿</span>
            </div>
            <div class="info">
                <div class="left"  @touchstart="touchstartFn1" @touchend="touchendFn1">
                    <div class="dots">
                        <div :class="Current1 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in 4"   :key="index"></div>
                    </div>
                    <el-carousel @change="change1"  ref="carousel1"  class="carousel" arrow="never" :interval="10000"   indicator-position="none">
                        <el-carousel-item v-for="(item , index) in 4" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/foodlive' + item + '.png' " alt="" class="img">
                                
                                
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    大会期间，代表们将统一入住会场所在酒店。酒店的设施完备，环境舒适优美。无论是酒店的硬件设施、还是人性化的服务管理，都满足BetterWorld全球青少年模拟联合国组委会对会场和住宿的高标准要求，进而为本次大会所有的参会代表以及家长们，在不出国门的情况下，感受世界级模拟联合国大会所带来的沉浸式体验和魅力。
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>餐饮</span>
            </div>
            <div class="info">
                <div class="left"  @touchstart="touchstartFn2" @touchend="touchendFn2">
                    <div class="dots">
                        <div :class="Current2 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in 1"   :key="index"></div>
                    </div>
                    <el-carousel @change="change2" ref="carousel2"  class="carousel" arrow="never" :interval="10000"   indicator-position="none">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/foodtwo' + item + '.png' " alt="" class="img">
                                
                                
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    大会期间的早餐是自助餐，帮忙代表们开启满满活力的一天。午餐是商务午餐，有助于大家就餐结束后，快速回归会议状态。晚餐是自助餐，帮助大家补充能量，以最好的状态迎接新的日程安排。
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>着装</span>
            </div>
            <div class="info">
                <div class="left"  @touchstart="touchstartFn3" @touchend="touchendFn3">
                    <div class="dots">
                        <div :class="Current3 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in 1"   :key="index"></div>
                    </div>
                    <el-carousel @change="change3"  ref="carousel3"  class="carousel" arrow="never" :interval="10000"   indicator-position="none">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/foodthree' + item + '.png' " alt="" class="img">
                                
                                
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    会议期间代表需穿正装出席会场。男生需穿西装套装、衬衫、领带、深色长袜、深色皮鞋。女生需穿西装套装或裙装、衬衫、皮鞋或高跟鞋。会场室内均有恒温空调，全程保持在一个舒适的温度。
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>交通</span>
            </div>
            <div class="info">
                <div class="left"  @touchstart="touchstartFn4" @touchend="touchendFn4">
                    <div class="dots">
                        <div :class="Current4 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in 1"   :key="index"></div>
                    </div>
                    <el-carousel @change="change4"  ref="carousel4"  class="carousel" arrow="never" :interval="10000"   indicator-position="none">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/foodfourth' + item + '.png' " alt="" class="img">
                                
                                
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    大家需自行前往会场，会场地址为：深圳国际会展中心皇冠假日酒店（广东省深圳市宝安区展云路8号）。会场距离深圳宝安国际机场约16公里，驾车约35分钟。会场距离地铁20号线国展南站C出口100米。
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {config} from './../../../utils/config'
import {banner} from './../../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                url : '',
            },
            load : false,
            configData : {},
            Current1 : 0,
            startMove1 : 0,
            Current2 : 0,
            startMove2 : 0,
            Current3 : 0,
            startMove3 : 0,
            Current4 : 0,
            startMove4 : 0,
        }
    },
    beforeMount(){
        if(this.$store.state.language.type == 1){
            this.configData = config.weiyuan.chinese
        }else{
            this.configData = config.weiyuan.english
        }
    },
    mounted(){
        
        banner({'seat' : 9,'device': 2}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    watch : {
		"$store.state.language" : function (){
			if(this.$store.state.language.type == 1){
				this.configData = config.weiyuan.chinese
			}else{
				this.configData = config.weiyuan.english
			}
		}
	},
    methods : {
        change1(Current1){
            this.Current1 = Current1
        },
        change2(Current2){
            this.Current2 = Current2
        },
        change3(Current3){
            this.Current3 = Current3
        },
        change4(Current4){
            this.Current4 = Current4
        },
        touchstartFn1(e){
            this.startMove1 = e.changedTouches[0].clientX
        },
        touchendFn1(e){
            if(e.changedTouches[0].clientX - this.startMove1 > 50 ){
                this.changePage1(-1)
            }else if(e.changedTouches[0].clientX - this.startMove1 < -50){
                this.changePage1(1)
            }
        },
        changePage1(page){
            if(page > 0){
                this.$refs.carousel1.next()
            }else{
                this.$refs.carousel1.prev()
            }
        },

        touchstartFn2(e){
            this.startMove2 = e.changedTouches[0].clientX
        },
        touchendFn2(e){
            if(e.changedTouches[0].clientX - this.startMove2 > 50 ){
                this.changePage2(-2)
            }else if(e.changedTouches[0].clientX - this.startMove2 < -50){
                this.changePage2(2)
            }
        },
        changePage2(page){
            if(page > 0){
                this.$refs.carousel2.next()
            }else{
                this.$refs.carousel2.prev()
            }
        },

        touchstartFn3(e){
            this.startMove3 = e.changedTouches[0].clientX
        },
        touchendFn3(e){
            if(e.changedTouches[0].clientX - this.startMove3 > 50 ){
                this.changePage3(-3)
            }else if(e.changedTouches[0].clientX - this.startMove3 < -50){
                this.changePage3(3)
            }
        },
        changePage3(page){
            if(page > 0){
                this.$refs.carousel3.next()
            }else{
                this.$refs.carousel3.prev()
            }
        },

        touchstartFn4(e){
            this.startMove4 = e.changedTouches[0].clientX
        },
        touchendFn4(e){
            if(e.changedTouches[0].clientX - this.startMove4 > 50 ){
                this.changePage4(-4)
            }else if(e.changedTouches[0].clientX - this.startMove4 < -50){
                this.changePage4(4)
            }
        },
        changePage4(page){
            if(page > 0){
                this.$refs.carousel4.next()
            }else{
                this.$refs.carousel4.prev()
            }
        },
    }
}
</script>

<style lang="less" scoped>
.food{
    padding-bottom: 50px;
    background: #1C1C1C;
    .typeBox{
        margin: 48px auto 0;
        width: 331px;
        .info{
            margin-top: 16px;
            .left{
                position: relative;
                /deep/ .carousel{
                    .el-carousel__container{
                        height: 160px;
                        background: #ccc;
                        border-radius: 8px;
                        overflow: hidden;
                        .content{
                            height: 100%;
                            img{
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .text{
                font-size: 14px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #fff;
                line-height: 24px;
                margin-top: 34px;
            }
            .color{
                color: #3DA6D5;
            }
        }
        .type{
            font-size: 24px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 31px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .typeBox1{
        margin-top: 24px;
    }
}
.videoBox{
    video{
        top: unset;
        bottom: 0;
    }
}
</style>