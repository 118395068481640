<template>
    <div class="food">
        <div class="videoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ $store.state.language.type == 1 ? data.title : data.title_en }}</div>
            <div class="title2">{{ $store.state.language.type == 1 ? data.describe : data.describe_en }}</div>
            <video :src="data.url" autoplay loop muted></video>
        </div>
        <div class="typeBox typeBox1">
            <div class="type">
                <span>大会流程</span>
                
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/canhuiOne' + item + '.png' " alt="" class="img">
                                <div class="button" @click="lookTemplate">查看模版</div>
                                <div class="model"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="right">
                    <div class="text">
                        <span>BetterWorld全球青少年模拟联合国大会，将严格按照联合国会议的规则进行，以保证每一位代表都有平等的发言权。代表在发言或是提问时需要注意措辞，尽量运用正式的外交辞令。大会流程简要分为“国家点名”、“确定议题”、“正式会议”。正式会议则包括了：发言、动议、问题、有主持核心磋商、自由磋商、意向条、投票表决等。</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>立场文件</span>
                
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/canhuiTwo' + item + '.png' " alt="" class="img">
                                <div class="button" @click="lookTemplate">查看模版</div>
                                <div class="model"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="right">
                    <div class="text">
                        立场文件是模拟联合国会议讨论的基础文件，它反映了各国针对会议所讨论问题的原则立场，并对如何解决上述问题提出本国的意见，它也是一国大会发言的基调。立场文件要力图真实、完整地反映模拟国家的立场。立场文件一般包含议题的国际形势，国际社会采取过的措施，更重要的是本国立场和措施。代表们需在大会开始前提交立场文件，篇幅要求不超过两页。
                    </div>
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>工作文件</span>
                
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/canhuiThree' + item + '.png' " alt="" class="img">
                                <div class="button" @click="lookTemplate">查看模版</div>
                                <div class="model"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="right">
                    <div class="text">
                        工作文件是当大会其中一个议题或某一部分讨论成熟时，即每位代表已阐述完基本观点后，由一个集团提交给主席的文件。在文件中应概述该集团对此问题统一立场、希望及解决方案。仅要求代表们在各自立场文件的基础上综合他国立场和要求，草拟出针对某一问题的看法和解决办法，不要求特定格式及投票。集团中的国家可以是同地域的、同民族的或是追求同种利益的。
                    </div>
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>发言稿</span>
                
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/canhuiFour' + item + '.png' " alt="" class="img">
                                <div class="button" @click="lookTemplate">查看模版</div>
                                <div class="model"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="right">
                    <div class="text">
                        在模拟联合国讨论国际和平与安全、发展、环境、人权等问题时，由于不同国家所处的地位不同，关心的问题也不一样，出现分歧和对立是正常的，代表们因立场不同而产生争论也十分常见。但是，十分重要的一点是，外交场上讲究的是以理服人，代表们可以义正言辞、语言犀利，但切忌不顾外交礼仪和个人身份，蛮横不讲理，甚至对代表个人进行人身攻击和谩骂。
                    </div>
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>决议草案</span>
                
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/canhuifive' + item + '.png' " alt="" class="img">
                                <div class="button" @click="lookTemplate">查看模版</div>
                                <div class="model"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="right">
                    <div class="text">
                        决议草案是在会议期间编写的文件，用于解决委员会正在讨论议题的解决方案。决议草案分为三个部分：标题部分、序言性条款和行动性条款。标题部分位于决议草案首页的上方，包括机构名称、委员会、主题、起草国和附议国。序言性条款主要列举问题产生的历史、以及联合国为解决该问题曾做出的努力。行动性条款是草案的实质部分，是为解决正在辩论的问题而提出的解决方案。
                    </div>
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>修正案</span>
                
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/canhuisix' + item + '.png' " alt="" class="img">
                                <div class="button" @click="lookTemplate">查看模版</div>
                                <div class="model"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="right">
                    <div class="text">
                        修正案分为友好修正案和非友好修正案，均需提交主席团。友好修正案是由除起草国外任意国家提出并由所有起草国附议后提交主席团。提交后即刻生效，无需投票。非友好修正案是由除起草国外任意国家代表提出遭部分或全部起草国反对的，它需要20%的起草国代表附议才能提交给主席团。随后进行讨论，将在决议草案之前被投票，三分之二多数赞成即可通过，成为决议草案的一部分。
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {config} from '../../utils/config'
import {banner} from '../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                url : '',
            },
            load : false,
            configData : {}
        }
    },
    beforeMount(){
        if(this.$store.state.language.type == 1){
            this.configData = config.weiyuan.chinese
        }else{
            this.configData = config.weiyuan.english
        }
    },
    mounted(){
        
        banner({'seat' : 8}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    methods : {
        lookTemplate(){
            this.$message({ message: '请登录小程序查看模版', type: 'success' })
        }
    },
    watch : {
		"$store.state.language" : function (){
			if(this.$store.state.language.type == 1){
				this.configData = config.weiyuan.chinese
			}else{
				this.configData = config.weiyuan.english
			}
		}
	}
}
</script>

<style lang="less" scoped>
.food{
    padding-bottom: 150px;
    background: #1C1C1C;
    .typeBox{
        margin-top: 64px;
        .info{
            display: flex;
            // align-items: center;
            justify-content: center;
            margin-top: 40px;
            .left{
                width: 587px;
                /deep/ .carousel{
                    height: 100%;
                    .el-carousel__container{
                        height: 284px;
                        border-radius: 8px;
                        overflow: hidden;
                        cursor: pointer;
                        .content{
                            height: 100%;
                        }
                        img{
                            height: 100%;
                        }
                        .button{
                            width: 170px;
                            height: 64px;
                            background: rgba(0,0,0,0.2);
                            border-radius: 8px;
                            border: 1px solid #FFFFFF;
                            font-size: 30px;
                            font-family: YouSheBiaoTiHei;
                            color: #FFFFFF;
                            line-height: 62px;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50% , -50%);
                            text-align: center;
                            z-index: 1;
                        }
                        .model{
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }
                    .el-carousel__container:hover{
                        .button{
                            background: #3DA6D5;
                            border-color: #3DA6D5;
                        }
                        .model{
                            background: rgba(0, 0, 0, 0.39);
                        }
                    }
                    .el-carousel__indicator--horizontal{
                        padding: 16px 15px 0 0;
                    }
                }
            }
            .right{
                width: 586px;
                margin-left: 27px;
                .text{
                    margin-top: 46px;
                    font-size: 20px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #FFFFFF;
                    line-height: 32px;
                }
            }
        }
        .type{
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 44px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 54px;
                height: 44px;
                margin-left: 30px;
            }
        }
    }
    .typeBox1{
        margin-top: 80px;
    }
}
.videoBox{
    video{
        top: unset;
        bottom: 0;
    }
}
</style>